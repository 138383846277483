import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { content } from './shared/routes/content-routes';
import { ContentLayoutComponent } from './shared/layout/content-layout/content-layout.component';
import { LoginComponent } from './components/auth/login/login.component';
import { AuthGuard } from './shared/models/guard/auth.guard';
import { EmployeeMarkerComponent } from './components/employee-marker/employee-marker.component';
import { environment } from 'src/environments/environment';

const routes: Routes = [
  { 
    path:'auth', 
    loadChildren: () => import('./components/auth/auth.module').then(m => m.AuthModule) 
  },
  {
    path: '',
    redirectTo: 'auth/login',
    pathMatch: 'full'
  },
  {
    path: '',
    component: ContentLayoutComponent,
    canActivate: [AuthGuard],
    children: content
  },
  {
    path: 'auth/login',
    loadChildren: () => import('../app/components/auth/auth.module').then(m => m.AuthModule),
  },
  {
    path: environment.nameRouting + "/:id",
    loadChildren: () => import('../app/components/employee-marker/employee-marker.module').then(m => m.EmployeeMarkerModule),
  },
  {
    path: environment.nameRouting,
    loadChildren: () => import('../app/components/employee-marker/employee-marker.module').then(m => m.EmployeeMarkerModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
