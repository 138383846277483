import { Injectable } from '@angular/core';
// import { User } from '../../models';
import { AngularFireFunctions } from '@angular/fire/functions';
import { FirestoreService } from '../firestore/firestore.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class StripeService {
  sub1: any;
  publickeyToPay: string = "";

  urlBase = environment.urlBase;
  apikey = environment.apikey;

  constructor(
    private functions: AngularFireFunctions,
    private firestore: FirestoreService,
    private http: HttpClient
  ) { }


  async createCustomerPost(messages) {
    return new Promise((resolve, reject) => {
      // console.log("http: ", messages);
      var raw = JSON.stringify(messages);
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        "api-key": this.apikey
      });

      this.http.post<any>(this.urlBase + "/StripeCreateCustomer-2", raw, { headers }).subscribe(data => {
        // console.log("createCustomerPost: ", data);
        resolve(data);
      }, error => {
        // console.log(error);
        reject(error);
      });
    });
  }

  createCustomer(val) {
    return new Promise((resolve, reject) => {
      const callable = this.functions.httpsCallable('stripeCreateCustomer');
      const obs = callable(val).subscribe(data => {
        resolve(data);
      }, ((e) => {
        // console.log(e);
        reject(e);
      }));
    });
  }

  makePayment(val) {
    return new Promise((resolve, reject) => {
      const callable = this.functions.httpsCallable('stripeMakePay');
      const obs = callable(val).subscribe(data => {
        resolve(data);
      }, ((e) => {
        console.log(e);
        reject(e);
      }));
    });
  }
}
