import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  getDatos: any;
  urlBase = environment.urlBaseEMMI;
  apikey = environment.apikeyEMMI;
  IdEmpresaInterna = environment.IdEmpresaInterna;

  constructor(
    private http: HttpClient
  ) { }

  sendGET_ProductsEMMI() {
    return this.getDatos = this.http.get<any>(this.urlBase + '/productos/' + this.apikey + '/' + this.IdEmpresaInterna);
  }

  async sendPOST_ProductsEMMI(messages: any) {
    return new Promise((resolve, reject) => {
      let raw = JSON.stringify(messages);
      // console.log("http: ", raw);
      this.http.post<any>(this.urlBase + '/cotizacionrapida/' + this.apikey, raw).subscribe(data => {
        console.log(data);
        resolve(data);
      }, error => {
        console.log(error);
        reject(error);
      });
    });
  }
}
