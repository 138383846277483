import { Component, Input, ViewEncapsulation } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { NavService, Menu } from '../../service/nav.service';
import { AuthService } from '../../service/auth/auth.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { FirestoreService } from '../../service/firestore/firestore.service';
import { User } from '../../models';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SidebarComponent {

  public menuItems: Menu[];
  public url: any;
  public fileurl: any;
  user = new User();
  displayName: string = '';
  photoURL: string = '';
  sub1: Subscription;
  isYellowGoAffiliate: boolean = false;

  constructor(
    private router: Router,
    public navServices: NavService,
    public auth: AuthService,
    private afAuth: AngularFireAuth,
    private firestore: FirestoreService,
  ) {
    this.navServices.items.subscribe(menuItems => {
      this.menuItems = menuItems
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          menuItems.filter(items => {
            if (items.path === event.url)
              this.setNavActive(items)
            if (!items.children) return false
            items.children.filter(subItems => {
              if (subItems.path === event.url)
                this.setNavActive(subItems)
              if (!subItems.children) return false
              subItems.children.filter(subSubItems => {
                if (subSubItems.path === event.url)
                  this.setNavActive(subSubItems)
              })
            })
          })
        }
      })
    })
  }

  // Active Nave state
  setNavActive(item) {
    this.menuItems.filter(menuItem => {
      if (menuItem != item)
        menuItem.active = false
      if (menuItem.children && menuItem.children.includes(item))
        menuItem.active = true
      if (menuItem.children) {
        menuItem.children.filter(submenuItems => {
          if (submenuItems.children && submenuItems.children.includes(item)) {
            menuItem.active = true
            submenuItems.active = true
          }
        })
      }
    })
  }

  // Click Toggle menu
  toggletNavActive(item) {
    if (!item.active) {
      this.menuItems.forEach(a => {
        if (this.menuItems.includes(item))
          a.active = false
        if (!a.children) return false
        a.children.forEach(b => {
          if (a.children.includes(item)) {
            b.active = false
          }
        })
      });
    }
    item.active = !item.active
  }

  //Fileupload
  readUrl(event: any) {
    if (event.target.files.length === 0)
      return;
    //Image upload validation
    var mimeType = event.target.files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    // Image upload
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      this.url = reader.result;
    }
  }

  ngOnInit(): void {
    this.loadAlerts();
  }

  loadAlerts() {
    if (this.sub1) { this.sub1.unsubscribe(); }
    this.afAuth.authState.subscribe((userFirebase: any) => {
      if (userFirebase) {
        // console.log('auth getUserFirebase() : ', userFirebase.uid);
        this.firestore.get('users/' + userFirebase.uid).then(ref => {
          // console.log('auth get() : ', ref);
          ref.valueChanges().subscribe((userLocal: any) => {
            // console.log('auth getUserFirestore() : ', userLocal);
            this.user = userLocal;
            this.loadCommerce(this.user.uid);
            this.displayName = userLocal.who_receives != undefined && userLocal.who_receives != null && userLocal.who_receives != '' ? userLocal.who_receives : '';
            this.photoURL = userLocal.photoURL != undefined && userLocal.photoURL != null && userLocal.photoURL != '' ? userLocal.photoURL : '../assets/img/profile.png';

          });
        }).catch((e) => {
        });
      }
    });
  }

  loadCommerce(val) {
    this.firestore.getWhere("commerces", "manager_user_uid", val).subscribe(res0 => {
      if (res0 && res0.length > 0) {
        // console.log("res0: ",res0);
        const comerce = res0.find(item => item.isYellowGoAffiliate === true);
        // console.log("comerce: ",comerce)
        if (comerce && comerce.isYellowGoAffiliate) {
          this.isYellowGoAffiliate = true;
        } else {
          let removeMenuItem = this.menuItems.filter(items => items.path !== '/kitchen');
          removeMenuItem = removeMenuItem.filter(items => items.path !== '/menu');
          
          this.menuItems = removeMenuItem;
        }
      }
    });
  }

}
