<div class="page-wrapper" [ngStyle]="background_image">
    <div class="authentication-box">
        <div class="container">
            <div class="row">
                <div class="col-md-5 p-0 card-left">
                    <div class="card bg-primary"
                        style="max-height: 490px; min-height: 490px; display: flex; justify-content: center;">
                        <div>
                            <div class="svg-icon" [ngStyle]="background">
                            </div>
                            <div class="single-item">
                                <owl-carousel-o [options]="owlcarouselOptions">
                                    <ng-container *ngFor="let data of owlcarousel">
                                        <ng-template carouselSlide class="item">
                                            <div>
                                                <h3>{{data.title}}</h3>
                                                <p>{{data.desc}}</p>
                                            </div>
                                        </ng-template>
                                    </ng-container>
                                </owl-carousel-o>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-7 p-0 card-right">
                    <div class="card tab2-card" style="max-height: 490px; min-height: 490px;">
                        <div class="card-body">
                            <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
                                <li [ngbNavItem]="1">
                                    <a ngbNavLink>Iniciar de Sesión</a>
                                    <ng-template ngbNavContent>
                                        <div class="tab-pane fade active show" id="account" role="tabpanel"
                                            aria-labelledby="account-tab">
                                            <form class="form-horizontal auth-form" novalidate="">
                                                <div class="form-group">
                                                    <label for="exampleInputEmail1" class="form-label">Email</label>
                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                        placeholder="admin@demo.com" inputmode="email" autocorrect='off'
                                                        pattern="email" autofocus="false" autocomplete="off" clearInput
                                                        Attribute='email' [(ngModel)]="email" name="email" type="email"
                                                        placeholder="ejemplo@email.com" ngModel>
                                                </div>
                                                <div class="form-group">
                                                    <label for="exampleInputEmail1"
                                                        class="form-label">Contraseña</label>
                                                    <input [type]="type_input" class="form-control"
                                                        id="exampleInputPassword1" autocomplete="current-password"
                                                        placeholder="******" inputmode="password" pattern="password"
                                                        required="true" autofocus="false" autocomplete="off"
                                                        Attribute='password' autocorrect='off' clearInput
                                                        [(ngModel)]="password" name="password" ngModel>
                                                </div>
                                                <div class="form-terms">
                                                    <div
                                                        class="custom-control custom-checkbox d-flex align-items-center flex-wrap">
                                                        <input type="checkbox" class="custom-control-input me-2"
                                                            (click)="ShowPassword()" id="customControlAutosizing">
                                                        <label class="custom-control-label"
                                                            for="customControlAutosizing">Mostrar Contraseña</label>
                                                    </div>
                                                </div>
                                                <div class="form-button">
                                                    <button class="btn btn-primary" type="submit"
                                                        (click)="onLoggedin($event)">
                                                        Ingresar
                                                    </button>
                                                    <!-- <button class="mx-3 btn btn-light" type="submit"
                                                        [routerLink]="['/marcador_empleado', 'login']"
                                                        routerLinkActive="router-link-active">
                                                        Marcar Entrada
                                                    </button> -->
                                                </div>
                                                <div class="form-button">
                                                    <div class="custom-control custom-checkbox d-flex align-items-center flex-wrap"
                                                        style="position: absolute;
                                                        bottom: 20px;
                                                        right: 20px;">
                                                        <a href="javascript:void(0)" (click)="askResetPass()"
                                                            class="btn btn-default forgot-pass ms-auto">olvido su
                                                            contraseña?</a>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </ng-template>
                                </li>
                            </ul>
                            <div [ngbNavOutlet]="nav" class="mt-2"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>