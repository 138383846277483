import { Component } from '@angular/core';
import { FirestoreService } from './shared/service/firestore/firestore.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { NotificationService } from './shared/service/notification/notification.service';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'multikart-backend';
  sub1: Subscription;
  tableDataEmployee = [];

  constructor(
    private firestore: FirestoreService,
    private afAuth: AngularFireAuth,
    private router: Router,
    public notification: NotificationService,
  ) {
    var path = window.location.pathname;
    var format_path = path.split('/');
    console.log("format_path: ", format_path)

    if (this.sub1) { this.sub1.unsubscribe(); }
    setTimeout(() => {
      this.sub1 = this.afAuth.authState.subscribe((userFirebase: any) => {
        if (userFirebase) {
          // console.log('auth getUserFirebase() : ', userFirebase.uid);
          this.firestore.get('users/' + userFirebase.uid).then(ref => {
            // console.log('auth get() : ', ref);
            ref.valueChanges().subscribe((userLocal: any) => {
              // console.log('auth userLocal() : ', userLocal);
              if (userLocal) {
                this.notification.init(userLocal.uid);
                if (!userLocal.is_enabled === true) {
                  // this.router.navigate(['/verification']);
                }
                // console.log("localStorage.getItem('session_employee')", localStorage.getItem('session_employee'))
                if (localStorage.getItem('session_employee') === null) {
                  this.firestore.getWhere('commerces', 'manager_user_uid', userLocal.uid).subscribe(async res => {
                    if (res && localStorage.getItem('session_employee') === null) {
                      this.tableDataEmployee = [];
                      await res.forEach(element => {
                        this.loadItemsEmployee(element.uid);
                      });
                      setTimeout(() => {
                        // console.log("tableDataEmployee", this.tableDataEmployee);
                        if (this.tableDataEmployee.length > 0) {
                          this.validateEmployeeSession();
                        }
                      }, 1500);
                    }
                  });
                } else {
                }
              }
            });
          }).catch((e) => {
          });
        } else {
          this.sub1.unsubscribe();
          Swal.close();
          localStorage.removeItem('session_employee');
          if (format_path[1] !== environment.nameRouting) {
            this.router.navigate(['/login']);
          }
        }
      });
    }, 600);
  }

  loadItemsEmployee(uid) {
    this.firestore.getAll('commerces/' + uid + '/employees').subscribe(res1 => {
      // console.log("loadItemsEmployee: ", res1)
      res1.forEach(element => {
        this.tableDataEmployee.push(element);
      });
    });
  }

  async validateEmployeeSession() {
    await Swal.fire({
      title: "Ingresa tu pin de empleado",
      icon: 'warning',
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showLoaderOnConfirm: true,
      confirmButtonText: 'Continuar',
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      // console.log("result: ", result);
      if (result.isConfirmed) {
        if (result.value !== '') {
          // console.log("tableDataEmployee", this.tableDataEmployee);
          const employee = this.tableDataEmployee.filter(item => Number(item.pin) === Number(result.value))
          // console.log("employee", employee);

          if (employee && employee.length > 0) {
            const [data] = employee;
            localStorage.setItem('session_employee', JSON.stringify(data));
            Swal.fire(
              'Bienvenido',
              '',
              'success'
            )
            setTimeout(() => {
              location.reload();
            }, 2000);
          } else {
            localStorage.removeItem('session_employee');
            Swal.fire(
              'Pin invalido',
              '',
              'info'
            );
            setTimeout(() => {
              this.validateEmployeeSession();
            }, 3000);
          }
        } else {
          Swal.fire(
            'Ingresa un pin',
            '',
            'info'
          );
          setTimeout(() => {
            this.validateEmployeeSession();
          }, 3000);
        }
      }
    })
  }

}
