import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, PRIMARY_OUTLET } from '@angular/router';
import { Subscription } from 'rxjs';

import { filter } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { User } from '../../models';
import { FirestoreService } from '../../service/firestore/firestore.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { AuthService } from '../../service/auth/auth.service';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {

  public breadcrumbs;
  public title: string;
  public description: string;
  public link: string = "";
  user = new User();
  sub1: Subscription;
  show_header: boolean = true;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public auth: AuthService,
    private afAuth: AngularFireAuth,
    private firestore: FirestoreService,
    ) {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .pipe(map(() => this.activatedRoute))
      .pipe(map((route) => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }))
      .pipe(filter(route => route.outlet === PRIMARY_OUTLET))
      .subscribe(route => {
        let snapshot = this.router.routerState.snapshot;
        let title = route.snapshot.data['title'];
        if (route.snapshot.data.hasOwnProperty('show_header') && route.snapshot.data.show_header === false) {
          this.show_header = false;
        }else{
          this.show_header = true;
        }
        let parent = route.parent.snapshot.data['breadcrumb'];
        let child = route.snapshot.data['breadcrumb'];
        let description = route.snapshot.data['description'];
        let link = route.snapshot.data['link'];
        this.breadcrumbs = {};
        this.title = title;
        this.link = link;
        this.description = description;
        this.breadcrumbs = {
          "parentBreadcrumb": parent,
          "childBreadcrumb": child
        }
      });
  }

  ngOnInit(): void {
    this.loadAlerts();
  }

  loadAlerts() {
    if (this.sub1) { this.sub1.unsubscribe(); }
    this.afAuth.authState.subscribe((userFirebase: any) => {
      if (userFirebase) {
        // console.log('auth getUserFirebase() : ', userFirebase.uid);
        this.firestore.get('users/' + userFirebase.uid).then(ref => {
          // console.log('auth get() : ', ref);
          ref.valueChanges().subscribe((userLocal: any) => {
            // console.log('auth getUserFirestore() : ', userLocal);
            this.user = userLocal;
          });
        }).catch((e) => {
        });
      } 
    });
  }

  ngOnDestroy() { }

}
