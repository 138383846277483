export class Verification {
  public obj: {};
  constructor(
    public uid: string,
    public user_uid: string,
    public issue_list: [],
    public data_complete: boolean,
    public data_checking: boolean,
    public data_approved: boolean,
    public dui_complete: boolean,
    public dui_checking: boolean,
    public dui_approved: boolean,
    public driver_license_complete: boolean,
    public driver_license_checking: boolean,
    public driver_license_approved: boolean,
    public pnc_solvency_complete: boolean,
    public pnc_solvency_checking: boolean,
    public pnc_solvency_approved: boolean,
    public criminal_solvency_complete: boolean,
    public criminal_solvency_checking: boolean,
    public criminal_solvency_approved: boolean,
    public dui_photo_front: string,
    public dui_photo_back: string,
    public license_photo_front: string,
    public license_photo_back: string,
    public pnc_photo_front: string,
    public pnc_photo_back: string,
    public criminal_photo_front: string,
    public criminal_photo_back: string,
  ) {
    this.obj = {
      uid: this.uid,
      user_uid: this.user_uid,
      issue_list: this.issue_list,
      data_complete: this.data_complete,
      data_checking: this.data_checking,
      data_approved: this.data_approved,
      dui_complete: this.dui_complete,
      dui_checking: this.dui_checking,
      dui_approved: this.dui_approved,
      driver_license_complete: this.driver_license_complete,
      driver_license_checking: this.driver_license_checking,
      driver_license_approved: this.driver_license_approved,
      pnc_solvency_complete: this.pnc_solvency_complete,
      pnc_solvency_checking: this.pnc_solvency_checking,
      pnc_solvency_approved: this.pnc_solvency_approved,
      criminal_solvency_complete: this.criminal_solvency_complete,
      criminal_solvency_checking: this.criminal_solvency_checking,
      criminal_solvency_approved: this.criminal_solvency_approved,
      dui_photo_front: this.dui_photo_front,
      dui_photo_back: this.dui_photo_back,
      license_photo_front: this.license_photo_front,
      license_photo_back: this.license_photo_back,
      pnc_photo_front: this.pnc_photo_front,
      pnc_photo_back: this.pnc_photo_back,
      criminal_photo_front: this.criminal_photo_front,
      criminal_photo_back: this.criminal_photo_back,
    };
  }
}
