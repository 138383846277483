import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { CropperComponent } from 'angular-cropperjs';
import { User } from 'src/app/shared/models';
import { AuthService } from 'src/app/shared/service/auth/auth.service';
import { FirestoreService } from 'src/app/shared/service/firestore/firestore.service';
import { StorageService } from 'src/app/shared/service/storage/storage.service';
import { StripeService } from 'src/app/shared/service/stripe/stripe.service';
import Swal from 'sweetalert2';
declare var Stripe;

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  public active = 1;
  @ViewChild('angularCropper') public angularCropper: CropperComponent;

  imageUrl: string = '../assets/images/others/placeholder.jpg';
  resultImage: any;

  // Plugin configuration
  config = {
    zoomable: false
  };

  //busqueda
  collectionName = 'users';
  collectionField = 'name';
  orderByDir: any = 'desc';
  collectionLimit = 1000;

  user = new User();
  photoURL: string = '';
  displayName: string = '';
  edit: boolean = false;
  cobro_afiliado: number = 0;
  show_pay: boolean = false;
  savedCard = {
    uid: '',
    last4: '',
    brand: '',
    exp_month: '',
    exp_year: '',
    funding: '',
    created: '',
    sourceToken: '',
    type: '',
    user_uid: '',
    status: true,
  };
  year_list: any[] = [];
  month_list: any[] = [];
  selectedrows: number;
  type_pay: string = "";

  email: string = '';
  holderName: string = '';
  cardNumber: string = '';
  month: string = '';
  year: string = '';
  cvc: string = '';
  keyStripe: string = '';
  private cardElement;
  private stripe;
  affiliate_membership_list: any[] = [];
  affiliate_membership_first: any = {};
  rows2 = [5, 10, 25, 50, 100];
  selectedrows2 = 10;
  cp: number = 1;
  
  constructor(
    public auth: AuthService,
    private firestore: FirestoreService,
    private storageS: StorageService,
    private elementRef: ElementRef,
    private stripeServices: StripeService,
    private calendar: NgbCalendar,
  ) {
    this.firestore.getOne('settings', ' yellow_go').subscribe(res => {
      this.cobro_afiliado = res.tarifa_comercios;
    })
    for (let index = 1; index < 13; index++) {
      this.month_list.push({
        id: (index < 10) ? "0" + index : index.toString(),
        name: (index < 10) ? "0" + index : index.toString()
      })
    }

    this.month_list

    let date = new Date();
    let year = date.getFullYear();
    this.selectedrows = year;
    this.year_list.length = 0;
    for (let index = 0; index < 11; index++) {
      this.year_list.push(year);
      year++;
    }
  }

  ngOnInit(): void {
    this.auth.getUser2().then(res0 => {
      // console.log("getUser2: ",res0);
      this.user = res0;
      this.displayName = res0.who_receives != undefined && res0.who_receives != null && res0.who_receives != '' ? res0.who_receives : '';
      this.photoURL = res0.photoURL != undefined && res0.photoURL != null && res0.photoURL != '' ? res0.photoURL : '../assets/img/profile.png';
      this.firestore.getWhere('affiliate_membership_sales', 'user_uid', this.user.uid).subscribe(async res => {
        // console.log("affiliate_membership_list: ",res);
        if (res.length !== 0) {
          res = await res.sort(function (a, b) {
            if (a.customer_createday && b.customer_createday) {
              var keyA = (a.customer_createday.seconds) ? a.customer_createday.seconds : a.customer_createday.second, keyB = (b.customer_createday.seconds) ? b.customer_createday.seconds : b.customer_createday.second;
              if (keyA < keyB) return 1;
              if (keyA > keyB) return -1;
              return 0;
            } else {
              return 0;
            }
          });
          this.affiliate_membership_list = res;
          let options;
          options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
          const format_date = new Date((res[0].customer_createday.seconds) ? res[0].customer_createday.seconds * 1000 : res[0].customer_createday.second * 1000);
          const format_date_next_payment = new Date((res[0].next_payment.seconds) ? res[0].next_payment.seconds * 1000 : res[0].next_payment.second * 1000);
          res[0].format_date = format_date.toLocaleDateString('es-ES', options);
          res[0].format_date_next_payment = format_date_next_payment.toLocaleDateString('es-ES', options);
          res[0].format_date_next_payment_getTime = format_date_next_payment.getTime();
          // console.log("res: ", res)
          this.affiliate_membership_first = res[0];
        }
      })
    })
  }

  initializeStripeElements() {
    // Verifica si Stripe.js está cargado
    if (typeof Stripe !== 'undefined') {
      this.firestore.getOne('settings', 'client').subscribe(res => {
        // res.stripe_mode_live = false;
        this.email = this.user.email;
        if (res.stripe_mode_live) {
          // console.log("stripe key: ", res.stripe_pk_live);
          this.stripe = Stripe(res.stripe_pk_live);
          this.keyStripe = res.stripe_pk_live;
          // Aquí puedes utilizar stripe para inicializar Stripe Elements
          const elements = this.stripe.elements();

          this.cardElement = elements.create('card', {
            hidePostalCode: true, // Ocultar el campo del código postal
          });
          this.cardElement.mount(this.elementRef.nativeElement.querySelector('#card-element'));
        } else {
          // console.log("stripe key: ", res.stripe_pk_test);
          this.stripe = Stripe(res.stripe_pk_test);
          this.keyStripe = res.stripe_pk_test;
          // Aquí puedes utilizar stripe para inicializar Stripe Elements
          const elements = this.stripe.elements();

          this.cardElement = elements.create('card', {
            hidePostalCode: true, // Ocultar el campo del código postal
          });
          this.cardElement.mount(this.elementRef.nativeElement.querySelector('#card-element'));
        }
      })
    } else {
      // Stripe.js no está cargado; maneja este caso adecuadamente
      console.error('Stripe.js no está cargado.');
    }
  }

  handleFileInput(event: any) {
    if (event.target.files.length) {
      var fileTypes = ['jpg', 'jpeg', 'png'];  //acceptable file types
      var extension = event.target.files[0].name.split('.').pop().toLowerCase(),  //file extension from input file
        isSuccess = fileTypes.indexOf(extension) > -1;  //is extension in acceptable types
      if (isSuccess) { //yes
        // start file reader
        const reader = new FileReader();
        const angularCropper = this.angularCropper;
        reader.onload = (event) => {
          if (event.target?.result) {
            angularCropper.imageUrl = event.target.result;
          }
        };
        reader.readAsDataURL(event.target.files[0]);
      } else { //no
        alert('Selected file is not an image. Please select an image file.')
      }
    }
  }

  cropImage() {
    this.resultImage = this.angularCropper.cropper.getCroppedCanvas().toDataURL();
    this.photoURL = this.angularCropper.cropper.getCroppedCanvas().toDataURL();
    this.user.photoURL = this.angularCropper.cropper.getCroppedCanvas().toDataURL();
    let dwn: HTMLElement = document.querySelector('.download') as HTMLElement;
    dwn.setAttribute('href', this.resultImage);
  }

  uploadData() {
    return new Promise((resolve, reject) => {

      this.firestore.update(this.collectionName, this.user.id, { ...this.user }).then((data) => {
        alert('informacion actualizada')
        resolve(true);
      }).catch(function (error) {
        console.log(error);
        alert('No se puede guardar.. Intentalo mas tarde');
        resolve(false);
      });

    });
  }

  sendForm() {
    Swal.fire({
      icon: 'info',
      title: 'Espera por favor...',
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      timerProgressBar: true,
      timer: 6e3
    });

    this.checkForm().then((data) => {
      if (data == null) {
        if (this.resultImage !== undefined && this.resultImage !== '') {
          this.savePhoto();
        } else {
          this.uploadData().then((data) => {
            this.edit = !this.edit;
            Swal.close();
            Swal.fire(
              'Listo!',
              'Guardado correctamente!',
              'success'
            )
          }).catch((e) => {
            this.edit = !this.edit;
          });
        }
      }
      else {
        alert(data);
      }
    }).catch(function (error) {
      console.log(error);
      alert(error);
    });
  }

  checkForm() {
    return new Promise((resolve, reject) => {
      let res = null;
      this.user.updated_at = new Date();

      if (this.user.username == null || this.user.username == '') { res = 'Nombre esta vacio' }
      if (this.user.direction == null || this.user.direction == '') { res = 'direccion esta vacio' }
      resolve(res);
    });
  }

  savePhoto() {
    this.dataURItoBlob(this.photoURL).then((blob: Blob) => {
      this.storageS.updatePhotoUser(this.collectionName, this.user.id, 1, blob).then((data) => {
        if (data) {
          this.user.photoURL = data['image'];
          this.user.image = data['file_path'];
          this.firestore.update(this.collectionName, this.user.id, { ...this.user }).then((data) => {
            this.edit = !this.edit;
            Swal.close();
            Swal.fire(
              'Listo!',
              'Guardado correctamente!',
              'success'
            )
          }).catch(function (error) {
            this.is_uploading = false;
            console.log(error);
            alert('No se puede guardar.. Intentalo mas tarde(1)');
          });
        } else {
          alert('No se puede guardar.. Intentalo mas tarde(2)');
        }
      }).catch((e) => {
        alert('No se puede guardar.. Intentalo mas tarde(3)');
      })
    }, (err) => {
      // Handle error
      console.log("Error dataURItoBlob(): ", err);
    });

  }

  dataURItoBlob(dataURI): Promise<Blob> {
    return new Promise((resolve) => {
      const arr = dataURI.split(',');
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      // console.log("resolve dataURItoBlob: ", new Blob([u8arr], { type: mime }));
      resolve(new Blob([u8arr], { type: mime }));
    });
  }

  async checkFieldsCard() {
    Swal.fire({
      icon: 'info',
      title: 'Espera por favor...',
      showConfirmButton: false,
      backdrop: 'static',
      allowOutsideClick: false,
    });
    if (this.type_pay === 'pago_reintegro') {

    } else {
      let message = '';
      if (
        this.holderName == null || this.holderName == "" &&
        this.email == null || this.email == ""
        // this.cardNumber == null || this.cardNumber == "" &&
        // this.cvc == null || this.cvc == "" &&
        // this.month == null || this.month == "" &&
        // this.year == null || this.year == ""
      ) {
        message += 'Completa todos los campos de tu tarjeta';
      }

      if (message != '') {
        Swal.close();
        Swal.fire(
          '',
          message,
          'info'
        )
      } else {
        this.onPay();
      }
    }
  }

  async onPay() {
    try {
      if (!this.stripe) {
        console.error('Stripe no está inicializado.');
        return;
      }

      // Obtén el token de la tarjeta directamente aquí
      const { token, error } = await this.stripe.createToken(this.cardElement, {
        name: this.holderName, // Nombre del titular
      });

      if (error) {
        console.error(error);
        Swal.close();
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error.message,
        })
        // Maneja el error apropiadamente, muestra un mensaje al usuario, etc.
      } else {
        // console.log(token); // Aquí tienes tu token de Stripe
        let next_payment = this.calendar.getNext(this.calendar.getToday(), 'm', 1);
        let toDate = new Date(next_payment.year, next_payment.month - 1, next_payment.day, 0, 0);

        if (this.affiliate_membership_list.length !== 0) {
          this.affiliate_membership_list[0].format_date_next_payment
          this.affiliate_membership_list[0].format_date_next_payment_getTime

          const date = new Date();
          const gettime = date.getTime();

          if (gettime < this.affiliate_membership_list[0].format_date_next_payment_getTime) {
            const newDatePayment = new Date(this.affiliate_membership_list[0].format_date_next_payment_getTime);
            const dataDate: any = {
              year: Number(newDatePayment.getFullYear()),
              month: Number(newDatePayment.getMonth() + 1),
              day: Number(newDatePayment.getDate())
            }
            // console.log("dataDate ",dataDate);
            next_payment = this.calendar.getNext(dataDate, 'm', 1);
            toDate = new Date(next_payment.year, next_payment.month - 1, next_payment.day, 0, 0);
          }
        }

        // console.log("toDate: ", toDate);

        this.stripeServices.createCustomerPost({
          email: this.email,
          name: this.holderName,
          uid: this.user.uid,
          id: token['id'],
          card: token['card'],
          created: token['created'],
          payment_amount: this.cobro_afiliado * 100,
          currency: 'usd',
          next_payment: toDate,
          description: 'Pago de membresía de afiliado',
          metadata: {
            user_uid: this.user.uid,
            email: this.user.email,
            next_payment: toDate,
          },
          time: new Date().getTime()
        }).then((res1: any) => {
          // console.log('createCustomer: ', res1);
          if (res1.success) {
            this.clearCardElement();
            const dataUser = {
              is_affiliate_member: true,
              is_paymentCard: true,
              type_payment_membership: this.type_pay,
              next_payment: toDate,
            };

            this.UpdateUserData(dataUser).then((data) => {});

            this.show_pay = false
            Swal.close();
            Swal.fire({
              icon: 'success',
              title: 'Listo',
              text: 'Pago realizado',
            })
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: (error.message) ? error.message : error,
            })
          }
        })

      }
    } catch (error) {
      console.error('Error al procesar el pago:', error);
      // Maneja el error apropiadamente, muestra un mensaje al usuario, etc.
      Swal.close();
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error,
      })
    }
  }

  clearCardElement() {
    // Llama al método clear() en el elemento de entrada de tarjeta para limpiar los datos
    this.holderName = "";
    this.email = "";
    this.cardElement.clear();
  }

  typePaymentManual() {
    const dataUser = {
      is_affiliate_member: true,
      is_paymentCard: false,
      type_payment_membership: this.type_pay,
    };

    this.UpdateUserData(dataUser).then((data) => {
      Swal.close();
      Swal.fire({
        icon: 'success',
        title: 'Listo',
        text: 'Información guardada',
      })
    });
  }

  UpdateUserData(dataUser) {
    return new Promise((resolve, reject) => {
      this.firestore.update(this.collectionName, this.user.id, { ...dataUser }).then((data) => {
        resolve(data);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  checkStatus(event: any) {
    // Check if radio button is checked
    if (event.target.checked == true) {

      // Get checked radio button's value
      let radioValue = event.target.value;
      this.type_pay = radioValue;

      if (this.type_pay === 'pago_manual') {
        this.initializeStripeElements()
      }
      // Print in console
      // console.log('event.target.value: ', event.target.value);
      // console.log('type_pay: ', this.type_pay);
    }
  }

  formatDate(date) {
    let format_date = new Date(date.seconds * 1000);
    let options;
    options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    let format_1 = format_date.toLocaleDateString('es-ES', options);
    return format_1;
  }
}
