import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgbDatepickerModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SettingRoutingModule } from './setting-routing.module';
import { ProfileComponent } from './profile/profile.component';
import { SharedModule } from '../../shared/shared.module';
import { AngularCropperjsModule } from 'angular-cropperjs';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxPaginationModule } from 'ngx-pagination';

@NgModule({
  declarations: [ProfileComponent],
  imports: [
    CommonModule,
    NgbModule,
    AngularCropperjsModule,
    ReactiveFormsModule,
    SettingRoutingModule,
    SharedModule,
    NgbDatepickerModule,
    NgSelectModule,
    NgxPaginationModule,
    FormsModule,
  ]
})
export class SettingModule { }
