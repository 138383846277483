export const environment = {
  version_test_web: "0.0.0",
  version_prod_web: "0.0.17",
  nameRouting: "marcaciones",
  
  production: false,
  config: {
    iconMode: 'ios',
    mode: 'ios',
    autoFocusAssist: false,
    menuType: 'overlay'
  },
  // Set language to use.
  language: 'en',
  firebase: {
    apiKey: "AIzaSyBK2IRRqZTjlQf_cRJu9Y1cW3s4ZFkoQGI",
    authDomain: "itwc-9657a.firebaseapp.com",
    databaseURL: "https://itwc-9657a.firebaseio.com",
    projectId: "itwc-9657a",
    storageBucket: "itwc-9657a.appspot.com",
    messagingSenderId: "557772790841",
    appId: "1:557772790841:web:a3d7e7236cc54b1db541e8"
  },
  // Firebase Cloud Messaging Server Key.
  // Get your gcmKey on https://console.firebase.google.com, under Overview -> Project Settings -> Cloud Messaging.
  // This is needed to send push notifications.
  gcmKey: 'AAAAgd3ZXDk:APA91bHSQx3FncVJyblQ__FsK0AQbLMxKLutJgBkKpQkpfhCzVQqooGJPvtKeUIgLQ_e50AbrfjZF2RiRGea55HD3AJuguGObF8pXvuN1RW-kYfp4EJ51357jCf1DAUE3HwlSzniAHnn',
  urlBase: 'https://us-central1-itwc-9657a.cloudfunctions.net',
  apikey: 'd4aea06c3bc-ea81d383c32f2-4355-49bd0d9c',
  urlBaseEMMI: 'https://sch.windowht.com/v2/emmi/yelou',
  apikeyEMMI: 'dd14494adb8-4d70f86456705-4b12-6153aee9',
  IdEmpresaInterna: 7,

  // CONEXION DEV
  // firebase: {
  //   apiKey: "AIzaSyAbCLmQ7dNWPdMtZHojNgudIl8GtEK_YW8",
  //   authDomain: "yelou-dev.firebaseapp.com",
  //   projectId: "yelou-dev",
  //   storageBucket: "yelou-dev.appspot.com",
  //   messagingSenderId: "587417785608",
  //   appId: "1:587417785608:web:a44e112f2e9a988719c8bd",
  //   measurementId: "G-5MTY4KZX6E"
  // },
  // gcmKey: 'AAAAiMTUFQg:APA91bHhH4hbR-UazczrRgPhM7uXecWUpmpbf527wVv9HPnERWaTP5s_qeCxvCT41JtQWz4tKFm5UcXCEjnjfQuJ1X8VbuLAtHNI3CcD9TnR1Xw3nftnPHNr3L41TJwaWJlwjEqp3r9E',
  // // urlBase: 'https://us-central1-yelou-dev.cloudfunctions.net',
  // urlBase: 'https://us-central1-yelou-dev.cloudfunctions.net',
  // apikey: 'f73b4a57c14-e682449685909-423a-34a384ea',
  // urlBaseEMMI: 'https://sch.galquisa.com/v2/emmi/yelou',
  // apikeyEMMI: 'bfadf7702bf-da6fff4fd629d-4d95-a42a5db4',
  // IdEmpresaInterna: 14,

  // You can find your googleWebClientId on your Firebase app console -> Authentication -> Sign-in Method -> Google -> Web client ID
  googleWebClientId: '557772790841-ua99eq4ttib987h4obt4aqjan5osjnqn.apps.googleusercontent.com',
  // Google Maps JavaScript API Key
  googleMapsKey: 'AIzaSyBK2IRRqZTjlQf_cRJu9Y1cW3s4ZFkoQGI',
};