import { Routes } from '@angular/router';

export const content: Routes = [
  {
    path: 'dashboard',
    loadChildren: () => import('../../components/dashboard/dashboard.module').then(m => m.DashboardModule),
  },
  {
    path: 'products',
    loadChildren: () => import('../../components/products/products.module').then(m => m.ProductsModule),
    data: {
      breadcrumb: "Products"
    }
  },
  {
    path: 'sales',
    loadChildren: () => import('../../components/sales/sales.module').then(m => m.SalesModule),
    data: {
      breadcrumb: "Sales"
    }
  },
  {
    path: 'coupons',
    loadChildren: () => import('../../components/coupons/coupons.module').then(m => m.CouponsModule),
    data: {
      breadcrumb: "Coupons"
    }
  },
  {
    path: 'pages',
    loadChildren: () => import('../../components/pages/pages.module').then(m => m.PagesModule),
    data: {
      breadcrumb: "Pages"
    }
  },
  {
    path: 'media',
    loadChildren: () => import('../../components/media/media.module').then(m => m.MediaModule),
  },
  {
    path: 'menus',
    loadChildren: () => import('../../components/menus/menus.module').then(m => m.MenusModule),
    data: {
      breadcrumb: "Menus"
    }
  },
  {
    path: 'users',
    loadChildren: () => import('../../components/users/users.module').then(m => m.UsersModule),
    data: {
      breadcrumb: "Users"
    }
  },
  {
    path: 'vendors',
    loadChildren: () => import('../../components/vendors/vendors.module').then(m => m.VendorsModule),
    data: {
      breadcrumb: "Vendors"
    }
  },
  {
    path: 'localization',
    loadChildren: () => import('../../components/localization/localization.module').then(m => m.LocalizationModule),
    data: {
      breadcrumb: "Localization"
    }
  },
  {
    path: 'reports',
    loadChildren: () => import('../../components/reports/reports.module').then(m => m.ReportsModule),
  },
  {
    path: 'orders',
    loadChildren: () => import('../../components/orders/orders.module').then(m => m.OrdersModule),
  },
  {
    path: 'cash-requests',
    loadChildren: () => import('../../components/cash-requests/cash-requests.module').then(m => m.CashRequestsModule),
  },
  {
    path: 'order-show/:id',
    loadChildren: () => import('../../components/order-show/order-show.module').then(m => m.OrderShowModule),
    data: {
      breadcrumb: "Ordenes"
    }
  },
  {
    path: 'payments',
    loadChildren: () => import('../../components/payments/payments.module').then(m => m.PaymentsModule),
  },
  {
    path: 'stores',
    loadChildren: () => import('../../components/stores/stores.module').then(m => m.StoresModule),
  },
  {
    path: 'reinstatement-affiliate',
    loadChildren: () => import('../../components/reinstatement-affiliate/reinstatement-affiliate.module').then(m => m.ReinstatementAffiliateModule),
  },
  {
    path: 'commerces-control/:id',
    loadChildren: () => import('../../components/commerces-control/commerces-control.module').then(m => m.CommercesControlModule),
    data: {
      breadcrumb: "Locales"
    }
  },
  {
    path: 'settings',
    loadChildren: () => import('../../components/setting/setting.module').then(m => m.SettingModule),
    data: {
      breadcrumb: "Settings"
    }
  },
  {
    path: 'products/:id',
    loadChildren: () => import('../../components/commerces-control/products/products.module').then(m => m.ProductsModule),
    data: {
      breadcrumb: "Locales",
    }
  },
  {
    path: 'new-order',
    loadChildren: () => import('../../components/orders/new-order/new-order.module').then(m => m.NewOrderModule),
    data: {
      title: "Nueva Orden",
      link: "orders",
      breadcrumb: "Nueva Orden",
      description: "Aquí puedes revisar crear una nueva orden",
      show_header: false
    }
  },
  {
    path: 'kitchen',
    loadChildren: () => import('../../components/kitchen/kitchen.module').then(m => m.KitchenModule),
  },
  {
    path: 'kitchen-details/:id',
    loadChildren: () => import('../../components/kitchen/kitchen-details/kitchen-details.module').then(m => m.KitchenDetailsModule),
    data: {
      title: "Detalle de cocina",
      link: "kitchen",
      breadcrumb: "Cocina",
      description: "Aquí puedes revisar el detalle de cocina"
    }
  },
  {
    path: 'menu',
    loadChildren: () => import('../../components/menu/menu.module').then(m => m.MenuModule),
  },
  {
    path: 'verification',
    loadChildren: () => import('../../components/verification/verification.module').then(m => m.VerificationModule),
  },
  {
    path: 'form-claim',
    loadChildren: () => import('../../components/form-claim/form-claim.module').then(m => m.FormClaimModule),
  },
  {
    path: 'solicitud-de-compra',
    loadChildren: () => import('../../components/cotizacion-rapida/cotizacion-rapida.module').then(m => m.CotizacionRapidaModule),
  },
  {
    path: 'promotions',
    loadChildren: () => import('../../components/promotions/promotions.module').then(m => m.PromotionsModule),
    data: {
      breadcrumb: "Promociones"
    }
  },
  {
    path: 'invoice',
    loadChildren: () => import('../../components/invoice/invoice.module').then(m => m.InvoiceModule),
    data: {
      breadcrumb: "Invoice"
    }
  }
];