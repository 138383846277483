<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row">
        <div class="col-xl-4">
            <div class="card">
                <div class="card-body">
                    <div class="profile-details text-center">
                        <img [src]="photoURL" alt=""
                            style="object-fit: cover; border: 3px solid gray; max-width: 100px; max-height: 100px; min-width: 100px; min-height: 100px;"
                            class="img-fluid img-90 rounded-circle blur-up lazyloaded">
                        <h5 class="f-w-600 mb-0">{{displayName}}</h5>
                        <span>{{user.email}}</span>
                        <div>
                            <button class="btn btn-outline-primary btn-icon-text" *ngIf="!edit" (click)="edit = !edit">
                                <app-feather-icons icon="edit" style="zoom: 0.8;"></app-feather-icons>
                                <!-- Editar Perfil -->
                            </button>
                            <div class="row" *ngIf="edit">
                                <div class="col-12 col-md-12 col-xl-12"
                                    style="margin-top: 5px; display: flex; justify-content: center;">
                                    <button class="btn btn-outline-success btn-icon-text" (click)="sendForm()">
                                        <app-feather-icons icon="save" style="zoom: 0.8;"></app-feather-icons>
                                        <!-- Guardar -->
                                    </button>
                                    <button class="btn btn-outline-danger btn-icon-text" (click)="edit = !edit"
                                        style="margin-left: 5px;">
                                        <app-feather-icons icon="x-circle" style="zoom: 0.8;"></app-feather-icons>
                                        <!-- Cancelar -->
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-8">
            <div class="card tab2-card">
                <div class="card-body">
                    <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
                        <li [ngbNavItem]="1">
                            <a ngbNavLink><i class="me-2" data-feather="user"></i>Datos Generales</a>
                            <ng-template ngbNavContent>
                                <div class="tab-pane fade show active" id="top-profile" role="tabpanel"
                                    aria-labelledby="top-profile-tab">
                                    <h5 class="f-w-600"> Perfil</h5>
                                    <div class="row">
                                        <div class="col-12 col-md-6 col-xl-6 mb-4">
                                            <h6 class="card-title">Correo del administrador</h6>
                                            <p class="tx-1 text-muted"><strong>{{user.email}}</strong></p>
                                        </div>
                                        <div class="col-12 col-md-6 col-xl-6 mb-4">
                                            <h6 class="card-title">Nombre del administrador</h6>
                                            <input type="text" class="form-control" id="exampleInputText1"
                                                [disabled]="!edit" placeholder="Ingresar Nombre"
                                                [(ngModel)]="user.firstName">
                                        </div>
                                        <div class="col-12 col-md-6 col-xl-6 mb-4">
                                            <h6 class="card-title">Apellido del administrador</h6>
                                            <input type="text" class="form-control" id="exampleInputText1"
                                                [disabled]="!edit" placeholder="Ingresar Nombre"
                                                [(ngModel)]="user.lastName">
                                        </div>
                                        <div class="col-12 col-md-6 col-xl-6 mb-4">
                                            <h6 class="card-title">Direccion</h6>
                                            <input type="text" class="form-control" id="exampleInputText1"
                                                [disabled]="!edit" placeholder="Ingresar Nombre"
                                                [(ngModel)]="user.direction">
                                        </div>
                                        <div class="col-12 col-md-6 col-xl-6 mb-4">
                                            <h6 class="card-title">Teléfono de contacto</h6>
                                            <input type="text" class="form-control" id="exampleInputText1"
                                                [disabled]="!edit" placeholder="Ingresar Nombre"
                                                [(ngModel)]="user.phone">
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </li>
                        <li [ngbNavItem]="2" *ngIf="edit">
                            <a ngbNavLink class="d-flex align-items-center"><i data-feather="image"
                                    class="me-2"></i>Imagen</a>
                            <ng-template ngbNavContent>
                                <div class="account-setting">
                                    <div class="row">
                                        <div class="col-md-8">
                                            <div class="mb-3">
                                                <h6 class="card-title mb-0">Edición de Foto de Perfil</h6>
                                                <input class="form-control" type="file" id="cropperImageUpload"
                                                    (change)="handleFileInput($event)">
                                            </div>
                                            <div>
                                                <angular-cropper #angularCropper [cropperOptions]="config"
                                                    [imageUrl]="imageUrl"></angular-cropper>
                                            </div>
                                            <div class="d-flex justify-content-between align-items-center flex-wrap">
                                                <div class="d-flex align-items-center flex-wrapp me-2 mt-3">
                                                    <button class="btn btn-primary crop mb-2 mb-md-0"
                                                        (click)="cropImage()">Recortarme</button>
                                                </div>
                                                <div class="mb-4 mb-md-0 mt-3">
                                                    <a href="" class="btn btn-outline-primary download"
                                                        download="imageName.png">Descargar imagen recortada</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4 ms-auto">
                                            <h6 class="text-muted mb-3">Imagen recortada: </h6>
                                            <img class="w-100 cropped-img mt-2" src="{{resultImage}}" alt="">
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </li>
                        <li [ngbNavItem]="3">
                            <a ngbNavLink><i class="me-2" data-feather="credit-card"></i>Membresía de Afiliado</a>
                            <ng-template ngbNavContent>
                                <div class="tab-pane fade show active" id="top-profile" role="tabpanel"
                                    aria-labelledby="top-profile-tab">
                                    <h5 class="f-w-600">Membresía de Afiliado</h5>
                                    <div class="row" *ngIf="!show_pay && affiliate_membership_list.length === 0">
                                        <div class="col-12 mb-1" style="display: flex; justify-content: center;">
                                            <div class="card card-hover">
                                                <img src="assets/img/header.png" alt=""
                                                    class="animate__animated animate__zoomIn"
                                                    style="max-height: 175px; position: absolute; top: 0px; right: 0px; border-radius: 15px;">
                                                <div class="card-body"
                                                    style="padding-bottom: 0px; display: flex; align-items: center;">
                                                    <h5 class="f-w-400"
                                                        style="text-align: center; line-height: normal; padding-right: 30px; padding-left: 30px;">
                                                        <h4 style="font-size: medium;">
                                                            <strong>
                                                                ¡Únete hoy como miembro afiliado por solo
                                                                ${{cobro_afiliado}}!
                                                            </strong>
                                                        </h4>
                                                        <h5 style="font-size: medium;">
                                                            No pierdas esta oportunidad de crecimiento y descuentos
                                                            especiales.
                                                            <strong>
                                                                ¡Forma parte de nuestra comunidad única!
                                                            </strong>
                                                        </h5>
                                                    </h5>
                                                </div>
                                                <img src="assets/img/footer.png" alt=""
                                                    class="animate__animated animate__zoomIn"
                                                    style="max-height: 175px; position: absolute; bottom: -1px; left: 0px; border-radius: 15px;">
                                            </div>
                                        </div>
                                        <div class="col-12 mb-4">
                                            <a class="btn btn-primary mx-2" (click)="show_pay = true">
                                                Continuar
                                            </a>
                                        </div>
                                    </div>
                                    <div class="row" *ngIf="!show_pay && affiliate_membership_list.length !== 0">
                                        <div class="col-12 mb-1" style="display: flex; justify-content: center;">
                                            <div class="card card-hover-2">
                                                <img src="assets/img/header.png" alt=""
                                                    class="animate__animated animate__zoomIn"
                                                    style="max-height: 175px; position: absolute; top: 0px; right: 0px; border-radius: 15px;">
                                                <div class="card-body"
                                                    style="padding-bottom: 0px; display: flex; align-items: center;">
                                                    <h5 class="row f-w-400 mx-3"
                                                        style="padding-right: 30px; padding-left: 30px;">
                                                        <h4 class="col-6 mb-3" style="font-size: medium;">
                                                            <strong>
                                                                Ultimo pago:
                                                            </strong>
                                                            <br>
                                                            {{affiliate_membership_first.format_date}}
                                                        </h4>
                                                        <h4 class="col-6 mb-3" style="font-size: medium;">
                                                            <strong>
                                                                Proximo pago:
                                                            </strong>
                                                            <br>
                                                            {{affiliate_membership_first.format_date_next_payment}}
                                                        </h4>
                                                        <h4 class="col-6 mb-3" style="font-size: medium;">
                                                            <strong>
                                                                Correo:
                                                            </strong>
                                                            <br>
                                                            <div style="text-transform: initial;">
                                                                {{affiliate_membership_first.receipt_email}}
                                                            </div>
                                                        </h4>
                                                        <h4 class="col-6 mb-3" style="font-size: medium;">
                                                            <strong>
                                                                Monto:
                                                            </strong>
                                                            <br>
                                                            $ {{affiliate_membership_first.amount}}
                                                        </h4>
                                                    </h5>
                                                </div>
                                                <img src="assets/img/footer.png" alt=""
                                                    class="animate__animated animate__zoomIn"
                                                    style="max-height: 175px; position: absolute; bottom: -1px; left: 0px; border-radius: 15px;">
                                            </div>
                                        </div>
                                        <div class="col-12 mb-4">
                                            <a class="btn btn-primary mx-2" (click)="show_pay = true">
                                                Pagar siguiente
                                            </a>
                                        </div>
                                    </div>
                                    <div class="row" *ngIf="show_pay">
                                        <div class="col-12 col-md-12 col-xl-12 mb-4">
                                            <div class="form-group">
                                                <label class="col-form-label">
                                                    <h5 class="f-w-400">Método de pago</h5>
                                                </label>
                                                <div
                                                    class="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
                                                    <label class="d-block mx-3" for="edo-ani1">
                                                        <input class="radio_animated" id="edo-ani1" type="radio"
                                                            name="rdo-ani" value="pago_manual"
                                                            (change)="checkStatus($event)">
                                                        Pago estándar
                                                    </label>
                                                    <label class="d-block mx-3" for="edo-ani">
                                                        <input class="radio_animated" id="edo-ani" type="radio"
                                                            name="rdo-ani" value="pago_reintegro"
                                                            (change)="checkStatus($event)">
                                                        Pago contra reintegro
                                                    </label>
                                                    <!-- ngbPopover='Al seleccionar el pago contra reintegro de las ventas, se le hara un descuento por el pago de la membresia de afiliado' -->
                                                    <span
                                                        ngbPopover='Al elegir la opción de pago a través de reembolso de las ventas, se aplicará un descuento correspondiente al costo de la membresía de afiliado.'
                                                        popoverTitle="Pago contra reintegro"
                                                        triggers="mouseenter:mouseleave">
                                                        <app-feather-icons icon="info"
                                                            style="zoom: 0.8; margin-right: 10px;">
                                                        </app-feather-icons>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-6 col-xl-6 mb-4" *ngIf="type_pay === 'pago_manual'">
                                            <h6 class="card-title">Correo</h6>
                                            <input type="email" class="form-control" id="card-email" name="card-email"
                                                placeholder="Ingresar Correo" [(ngModel)]="email">
                                        </div>
                                        <div class="col-12 col-md-6 col-xl-6 mb-4" *ngIf="type_pay === 'pago_manual'">
                                            <h6 class="card-title">Nombre del Titular</h6>
                                            <input type="text" class="form-control" id="card-name" name="card-name"
                                                placeholder="Ingresar Nombre" [(ngModel)]="holderName">
                                        </div>
                                        <div class="col-12 col-md-6 col-xl-6 mb-4" *ngIf="type_pay === 'pago_manual'">
                                            <h6 class="card-title">Número de tarjeta</h6>
                                            <div id="card-element" #cardElement>
                                                <!-- Elemento de entrada de tarjeta de Stripe se renderizará aquí -->
                                            </div>
                                            <!-- Utiliza un contenedor para mostrar errores -->
                                            <div id="card-errors" role="alert"></div>
                                        </div>
                                        <!-- <div class="col-12 col-md-6 col-xl-6 mb-4" *ngIf="type_pay === 'pago_manual'">
                                            <h6 class="card-title">Mes de expiración</h6>
                                            <ng-select [items]="month_list" bindLabel="name" bindValue="id" [(ngModel)]="month" [placeholder]="month_list[0].id">
                                            </ng-select>
                                          </div>
                                          <div class="col-12 col-md-6 col-xl-6 mb-4" *ngIf="type_pay === 'pago_manual'">
                                            <h6 class="card-title">Año de expiración</h6>
                                            <ng-select [items]="year_list" [placeholder]="year_list[0]" [(ngModel)]="year">
                                            </ng-select>
                                          </div>
                                          <div class="col-12 col-md-6 col-xl-6 mb-4" *ngIf="type_pay === 'pago_manual'">
                                            <h6 class="card-title">CVC</h6>
                                            <input type="text" class="form-control" id="cvc" name="cvc" [(ngModel)]="cvc" placeholder="***">
                                          </div> -->
                                        <div class="col-12 mb-4">
                                            <a class="btn btn-danger mx-2" (click)="show_pay = false">
                                                Volver
                                            </a>
                                            <span *ngIf="type_pay === ''" ngbPopover='Seleccione un método de pago'
                                                triggers="mouseenter:mouseleave">
                                                <a class="btn btn-primary disabled mx-2" disabled>
                                                    Continuar
                                                </a>
                                            </span>
                                            <a *ngIf="type_pay !== '' && type_pay === 'pago_manual'"
                                                class="btn btn-primary mx-2" (click)="checkFieldsCard()">
                                                Continuar
                                            </a>
                                            <!-- <a *ngIf="type_pay !== '' && type_pay === 'pago_manual'"
                                                class="btn btn-primary mx-2" (click)="clearCardElement()">
                                                limpiar
                                            </a> -->
                                            <a *ngIf="type_pay !== '' && type_pay === 'pago_reintegro'"
                                                class="btn btn-primary mx-2" (click)="typePaymentManual()">
                                                Guardar
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </li>
                        <li [ngbNavItem]="4">
                            <a ngbNavLink><i class="me-2" data-feather="book-open"></i>Historial de Pago</a>
                            <ng-template ngbNavContent>
                                <div class="tab-pane fade show active" id="top-profile" role="tabpanel"
                                    aria-labelledby="top-profile-tab">
                                    <h5 class="f-w-600">Historial de Pago</h5>
                                    <div class="row">
                                        <div class="col-12 mb-4">
                                            <div class="table-responsive">
                                                <table id="dataTableExample" class="table">
                                                    <thead>
                                                        <tr>
                                                            <th class="pt-0">
                                                                Correo
                                                            </th>
                                                            <th class="pt-0">Monto</th>
                                                            <th class="pt-0">Fecha de pago</th>
                                                            <th class="pt-0">Siguiente pago</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr
                                                            *ngFor="let item of affiliate_membership_list | paginate: { itemsPerPage: selectedrows2, currentPage: cp }; let i = index">
                                                            <td>
                                                                {{ item.receipt_email || 'N/A' }}
                                                            </td>
                                                            <td>{{item.amount}}</td>
                                                            <td>{{formatDate(item.customer_createday)}}</td>
                                                            <td>{{formatDate(item.next_payment)}}</td>
                                                        </tr>
                                                        <tr *ngIf="affiliate_membership_list.length === 0">
                                                            <td colspan="3">
                                                                <div>
                                                                    Sin pagos procesadas
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                    <tfoot>
                                                        <tr>
                                                            <td colspan="8">
                                                                <div style="display: flex; justify-content: flex-end;">
                                                                    <pagination-controls (pageChange)="cp = $event"
                                                                        maxSize="5" autoHide="true">
                                                                    </pagination-controls>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tfoot>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </li>
                    </ul>
                    <div [ngbNavOutlet]="nav" class="mt-2"></div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->