import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { FirestoreService } from '../firestore/firestore.service';

@Injectable({
  providedIn: 'root'
})
export class FirestoreservicesService {

  arrayAlerts = new BehaviorSubject([]);
  arrayModelAll = new BehaviorSubject([]);
  arrayModelAll_2 = new BehaviorSubject([]);
  arrayModelAll_3 = new BehaviorSubject([]);
  arrayModelAll_4 = new BehaviorSubject([]);

  constructor(
    private fs: FirestoreService,
  ) { }

  getAlertsAsync(uid: any, collectionName): Observable<any> {
    if ((this.arrayAlerts.value.length == 0)) {
      this.fs.getWhere2(collectionName, 'manager_uid', uid, 'is_notified_commerce', false).subscribe(res1 => {
        this.setAlerts(res1).then((res2) => {
        }), (e: any) => { console.log(e); };
      }), (e: any) => { console.log(e); };
    }
    return this.arrayAlerts.asObservable();
  }

  setAlerts(val1: never[]) {
    return new Promise((resolve, reject) => {
      this.arrayAlerts.next(val1);
      resolve(true);
    });
  }

  getAllAsync(uid, collectionName) {
    this.fs.getWhere(collectionName, 'manager_uid', uid).subscribe(res1 => {
      this.shortByCreatedAt(res1).then((res2) => {
        this.setAll(res1).then((res3) => {
        }), e => { console.log(e); };
      }), e => { console.log(e); };
    }), e => { console.log(e); };
    return this.arrayModelAll.asObservable();
  }
  
  setAll(val1) {
    return new Promise((resolve, reject) => {
      this.arrayModelAll.next(val1);
      resolve(true);
    });
  }

  setAll2(val1) {
    return new Promise((resolve, reject) => {
      this.arrayModelAll_2.next(val1);
      resolve(true);
    });
  }

  setAll3(val1) {
    return new Promise((resolve, reject) => {
      this.arrayModelAll_3.next(val1);
      resolve(true);
    });
  }

  setAll4(val1) {
    return new Promise((resolve, reject) => {
      this.arrayModelAll_4.next(val1);
      resolve(true);
    });
  }

  shortByCreatedAt(val1) {
    return new Promise((resolve, reject) => {
      val1.sort(function (a, b) {
        if (a.created_at > b.created_at) { return -1; }
        if (a.created_at < b.created_at) { return 1; }
        return 0;
      });
      resolve(val1);
    });
  }

  getAll(uid,collectionName) {
    if ((this.arrayModelAll_2.value.length == 0 && uid != null && uid != undefined)) {
      // console.log(uid)
      this.fs.getWhere(collectionName, 'manager_user_uid', uid).subscribe(res1 => {
        //console.log('commerces array '+ JSON.stringify((res1)));
        this.shortByCreatedAt(res1).then((res2) => {
          this.setAll2(res1).then((res3) => {
          }), e => { console.log(e); };
        }), e => { console.log(e); };
      }), e => { console.log(e); };
    } 
    return this.arrayModelAll_2.asObservable();
  }

  getAllCategories(uid,collectionName) {
    if ((this.arrayModelAll_3.value.length == 0 && uid != null && uid != undefined)) {
      // console.log(uid)
      this.fs.getWhere(collectionName, 'commerce_uid', uid).subscribe(res1 => {
        //console.log('commerces array '+ JSON.stringify((res1)));
        this.shortByCreatedAt(res1).then((res2) => {
          this.setAll3(res1).then((res3) => {
          }), e => { console.log(e); };
        }), e => { console.log(e); };
      }), e => { console.log(e); };
    } 
    return this.arrayModelAll_3.asObservable();
  }

  getAllCategoriesMain(uid,collectionName) {
    if ((this.arrayModelAll_4.value.length == 0 && uid != null && uid != undefined)) {
      // console.log(uid)
      this.fs.getWhere(collectionName, 'commerce_uid', uid).subscribe(res1 => {
        //console.log('commerces array '+ JSON.stringify((res1)));
        this.shortByCreatedAt(res1).then((res2) => {
          this.setAll4(res1).then((res3) => {
          }), e => { console.log(e); };
        }), e => { console.log(e); };
      }), e => { console.log(e); };
    } 
    return this.arrayModelAll_4.asObservable();
  }
}
