import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import * as chartData from '../../shared/data/chart';
import { doughnutData, pieData } from '../../shared/data/chart';
import { Subscription } from 'rxjs';
import { User } from 'src/app/shared/models';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/service/auth/auth.service';
import { FirestoreservicesService } from 'src/app/shared/service/firestoreservices/firestoreservices.service';
import { FirestoreService } from 'src/app/shared/service/firestore/firestore.service';
import { NgbCalendar, NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { HttpService } from 'src/app/shared/service/http/http.service';
import { DropzoneConfigInterface, DropzoneDirective } from 'ngx-dropzone-wrapper';
import { StorageService } from 'src/app/shared/service/storage/storage.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { environment } from 'src/environments/environment';
import { CommonService } from 'src/app/shared/service/common/common.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  public doughnutData = doughnutData;
  public pieData = pieData;

  revenueChartOptions: any = {};
  obj = {
    primary: "#E0B006",
    secondary: "#7987a1",
    success: "#05a34a",
    info: "#66d1d1",
    warning: "#fbbc06",
    danger: "#ff3366",
    light: "#e9ecef",
    dark: "#060c17",
    muted: "#7987a1",
    gridBorder: "rgba(77, 138, 240, .15)",
    bodyColor: "#000",
    cardBg: "#fff",
    fontFamily: "'Roboto', Helvetica, sans-serif"
  }
  sub1: Subscription;
  sub2: Subscription;
  user = new User();
  count_orders_date: number = 0;
  count_orders_complete_date: number = 0;
  date_from: string = "";
  date_up_to: string = "";

  count_orders: number = 0;
  count_orders_complete: number = 0;
  profitsPerMonth = 0;
  profitsPerMonth_date = "";
  profitsPerDay = 0;
  profitsPerDay_date = "";
  profitsPerWeek = 0;
  profitsPerWeek_date = "";
  schedule = [];
  commerces_names = [];
  commerces_status = [];
  latest_orders = [];
  orders: any;
  prueba = [];
  day = "";
  btn_date = {
    today: {
      class: "btn btn-outline-primary"
    },
    week: {
      class: "btn btn-outline-primary"
    },
    month: {
      class: "btn btn-primary"
    },
    year: {
      class: "btn btn-outline-primary"
    },
  }
  btn_top_sales = {
    categories: {
      class: "btn btn-primary",
      active: true
    },
    products: {
      class: "btn btn-outline-primary",
      active: false
    }
  }
  refresh: boolean = true;
  show_message_no_data: boolean = false;
  show_filter_date_chart: boolean = false;
  data_categories: any[] = [];
  data_products: any[] = [];
  data_commerces: any[] = [];

  data_sale_categories: any = {};
  data_sale_products: any = {};
  selectedDate1: NgbDateStruct;
  selectedDate2: NgbDateStruct;
  view: string = "Corte De Caja Diario - ";
  model: any = {
    cash_payment: 0,
    n1co_payment: 0,
    ianos_payment: 0,
  };
  dataCommerce: any = {};
  tableDataEmployee = [];
  public dataEmployee: any = {};
  messageInvoiceEMMI = {
    pk_emmi_company: 0,
    pk_customer: 0,
    pk_branch: 0,
    pk_seller: 0,
    pk_payment_condition: 0,
    pk_ofert_time: 0,
    pk_kind_invoice: 0,
    pk_user: 0,
    external_invoice: 0,
    pk_quote_kind: 0,
    pk_storage: 0,
    pk_method_payment: {
      pk_card: 0,
      pk_cash: 0,
    },
    quote_comments: "",
    items: [],
    contacts: []
  };
  itemsCotizacion: any[] = [];
  salesOfTheDayCash = {
    totalFlete: 0,
    totalFleteOutIva: 0,
    totalFleteOnlyIva: 0,
    quantityFlete: 0,
    commentFlete: "",
    totalExtras: 0,
    totalExtrasOutIva: 0,
    totalExtrasOnlyIva: 0,
    quantityExtras: 0,
    commentExtras: "",
    items: [],
    extras: []
  };
  salesOfTheDayCard = {
    totalFlete: 0,
    totalFleteOutIva: 0,
    totalFleteOnlyIva: 0,
    quantityFlete: 0,
    commentFlete: "",
    totalExtras: 0,
    quantityExtras: 0,
    totalExtrasOutIva: 0,
    totalExtrasOnlyIva: 0,
    commentExtras: "",
    items: [],
    extras: []
  };
  salesOfTheDayN1co = {
    totalFlete: 0,
    totalFleteOutIva: 0,
    totalFleteOnlyIva: 0,
    quantityFlete: 0,
    commentFlete: "",
    totalExtras: 0,
    quantityExtras: 0,
    totalExtrasOutIva: 0,
    totalExtrasOnlyIva: 0,
    commentExtras: "",
    items: [],
    extras: []
  };
  allProducts: any[] = [];
  itemsNotEMMIRelationClone: any[] = [];

  productoEMMI: any = {
    productExtras: {},
    productFlete: {}
  };
  public config: DropzoneConfigInterface = {
    clickable: true,
    maxFiles: 5,
    autoReset: null,
    errorReset: null,
    cancelReset: null,
    addRemoveLinks: true,
  };

  private collectionName = '/cutdiaryopening/images/';
  postCutDiaryOpening: any = {};
  imagesCutDiaryOpening: any[] = [];
  @ViewChild(DropzoneDirective, { static: false }) directiveRef?: DropzoneDirective;
  @ViewChild('lgModal_2') xlModal: any;
  marcadorPath: string = environment.nameRouting;
  itemsNotEMMIRelation: any[] = [];
  CommerceName: any = '';
  CommerceUid: any = '';
  isYellowGoAffiliate: boolean = false;

  constructor(
    public auth: AuthService,
    private ordersDB: FirestoreservicesService,
    private firestore: FirestoreService,
    private router: Router,
    private calendar: NgbCalendar,
    private modalService: NgbModal,
    private storage: StorageService,
    private http: HttpService,
    private commonServices: CommonService,
    private afs: AngularFirestore
  ) {
    Object.assign(this, { doughnutData, pieData });
    this.dataEmployee = JSON.parse(localStorage.getItem('session_employee'));
  }

  // events
  public chartClicked(e: any): void {
  }
  public chartHovered(e: any): void {
  }

  ngOnInit() {
    if (this.sub1) { this.sub1.unsubscribe(); }
    this.auth.getUser2().then((res0: any) => {
      // console.log("getUser2: ",res0)

      this.user = res0;

      if (res0.hasOwnProperty('quick_quote')) {
        this.messageInvoiceEMMI.contacts = res0.quick_quote.contacts;
        this.messageInvoiceEMMI.pk_branch = res0.quick_quote.pk_branch;
        this.messageInvoiceEMMI.pk_customer = res0.quick_quote.pk_customer;
        this.messageInvoiceEMMI.pk_emmi_company = res0.quick_quote.pk_emmi_company;
        this.messageInvoiceEMMI.pk_seller = res0.quick_quote.pk_seller;
        this.messageInvoiceEMMI.pk_payment_condition = res0.quick_quote.pk_payment_condition;
        this.messageInvoiceEMMI.pk_ofert_time = res0.quick_quote.pk_ofert_time;
        this.messageInvoiceEMMI.pk_kind_invoice = res0.quick_quote.pk_kind_invoice;
        this.messageInvoiceEMMI.pk_user = res0.quick_quote.pk_user;
        this.messageInvoiceEMMI.pk_quote_kind = res0.quick_quote.pk_quote_kind;
        this.messageInvoiceEMMI.pk_storage = res0.quick_quote.pk_storage;
      }

      this.ordersDB.getAlertsAsync(res0.uid, 'orders').subscribe(res2 => {
        // console.log("getAlertsAsync: ",res2)
        if (res2.length > 0) {
          let news = res2.filter(item => item['orders_status_uid'] == 'Creada');
          let complete = res2.filter(item => item['orders_status_uid'] == 'Entregada');
          res2 = res2.sort(function (a, b) {
            var keyA = a.created_at.seconds, keyB = b.created_at.seconds;
            if (keyA < keyB) return 1;
            if (keyA > keyB) return -1;
            return 0;
          });
          this.latest_orders = res2.slice(0, 5);
          // console.log("latest_orders: ",this.latest_orders)
          if (news.length > 0) {
            this.count_orders = news.length;
          } else {
            this.count_orders = 0;
          }

          if (complete.length > 0) {
            this.count_orders_complete = complete.length;
          } else {
            this.count_orders_complete = 0;
          }
        }
      }, (e) => { console.log(e) });
      this.getProfitPerMonth();
      this.getProfitPerDay();
      this.getProfitPerWeek();
      this.getCommerces();
      this.getRevenueChartOptions(this.obj);
      this.TopCategoriesSales();
    }).catch(err => {
      console.log("err: ", err)
      this.router.navigate(['/auth/login']);
    });
  }

  onUploadSuccess(event: any): void {
    console.log(event);
    event.addedFiles.forEach(async (element, index) => {
      await this.storage.updatePhotoWeb(this.collectionName, element, 'name', index).then(res => {
        let item = {
          url_image: res['image'],
          alt: element.name,
        };
        this.postCutDiaryOpening.images = (this.postCutDiaryOpening.images && this.postCutDiaryOpening.images.length > 0) ? this.postCutDiaryOpening.images : [];
        this.postCutDiaryOpening.images.push(item);
        this.imagesCutDiaryOpening.push(element);

        // console.log("postCutDiaryOpening.images", this.postCutDiaryOpening.images);
        // console.log("imagesCutDiaryOpening", this.imagesCutDiaryOpening);

      }).catch(err => {
      })
    });
  }

  onremovedFile(event: any) {
    let index = this.imagesCutDiaryOpening.findIndex(item => item.name == event['name']);
    if (index > -1) this.imagesCutDiaryOpening.splice(index, 1);

    let index2 = this.postCutDiaryOpening.images.findIndex(item => item.alt == event['name']);
    if (index2 > -1) this.postCutDiaryOpening.images.splice(index, 1);

    // console.log("postCutDiaryOpening.images", this.postCutDiaryOpening.images);
    // console.log("imagesCutDiaryOpening", this.imagesCutDiaryOpening);
  }

  onUploadError(event: any): void {
  }

  resetDropzoneUploads(): void {
    if (this.directiveRef) this.directiveRef.reset();
  }


  async openModal(item, content: TemplateRef<any>) {
    // console.log("localStorage.getItem('session_employee')", localStorage.getItem('session_employee'))
    if (localStorage.getItem('session_employee') !== null) {
      this.view += item.name;
      this.dataCommerce = item;
      this.modalService.open(content, { size: 'lg' }).result.then((result) => {
      }).catch((res) => { });
    } else {
      this.loadItemsEmployee(item.uid);
    }
  }

  async makeDiaryOpening(item) {
    if (localStorage.getItem('session_employee') !== null) {
      this.dataCommerce = item;
      this.modalService.open(this.xlModal, { size: 'xl' }).result.then((result) => {
        // console.log("Modal closed", result);
      }).catch((res) => {
        // console.log("Modal closed", res);
      });
    } else {
      this.loadItemsEmployee(item.uid);
    }
  }

  async saveDiaryOpening() {
    await Swal.fire({
      title: `Estas seguro de querer realizar la apertura de caja?`,
      icon: 'warning',
      showDenyButton: true,
      confirmButtonText: 'Continuar',
      denyButtonText: `Cancelar`,
    }).then((result) => {
      if (result.isConfirmed) {
        const uidCutDiaryOpening = this.afs.createId();
        this.dataCommerce.last_daily_opening = new Date();
        const employee = JSON.parse(localStorage.getItem('session_employee'));
        this.dataCommerce.last_daily_opening_by = employee.name;
        this.dataCommerce.cash_flow_statement = true;
        this.dataCommerce.last_daily_opening_uid = uidCutDiaryOpening;

        this.firestore.update("commerces", this.dataCommerce.uid, { ...this.dataCommerce }).then(res => {
          const data = {
            uid: uidCutDiaryOpening,
            last_daily_opening: this.dataCommerce.last_daily_opening,
            last_daily_opening_by: this.dataCommerce.last_daily_opening_by,
            cash_flow_statement: this.dataCommerce.cash_flow_statement,
            last_daily_opening_by_uid: employee.id,
            ...this.postCutDiaryOpening
          };
          this.firestore.saveSubcollection("commerces", this.dataCommerce.uid, "daily_opening_log", data.uid, { ...data }).then(res => {
            Swal.close();
            Swal.fire(
              "Listo",
              "Apertura de caja registrado.",
              'success'
            )
            this.modalService.dismissAll();
          });
        });
      }
    });
  }

  async makeDiaryClosing(item) {
    if (localStorage.getItem('session_employee') !== null) {
      this.dataCommerce = item;
      await Swal.fire({
        title: `Estas seguro de querer realizar el cierre de caja?`,
        icon: 'warning',
        showDenyButton: true,
        confirmButtonText: 'Continuar',
        denyButtonText: `Cancelar`,
      }).then((result) => {
        if (result.isConfirmed) {
          this.dataCommerce.last_daily_opening = new Date();
          const employee = JSON.parse(localStorage.getItem('session_employee'));
          this.dataCommerce.last_daily_opening_by = employee.name;
          this.dataCommerce.cash_flow_statement = false;
          this.firestore.update("commerces", this.dataCommerce.uid, { ...this.dataCommerce }).then(res => {
            const data = {
              uid: "",
              last_daily_opening: this.dataCommerce.last_daily_opening,
              last_daily_opening_by: this.dataCommerce.last_daily_opening_by,
              cash_flow_statement: this.dataCommerce.cash_flow_statement,
              last_daily_opening_by_uid: employee.id
            };
            this.firestore.saveSubcollection("commerces", this.dataCommerce.uid, "daily_opening_log", data.uid, { ...data }).then(res => {
              Swal.close();
              Swal.fire(
                "Listo",
                "Cierre de caja registrado.",
                'success'
              )
              this.modalService.dismissAll();
            });
          });
        }
      });
    } else {
      this.loadItemsEmployee(item.uid);
    }
  }

  loadItemsEmployee(uid) {
    this.firestore.getAll('commerces/' + uid + '/employees').subscribe(async res1 => {
      this.tableDataEmployee.length = 0;
      // console.log("loadItemsEmployee: ", res1);
      if (res1 && localStorage.getItem('session_employee') === null) {
        await res1.forEach(element => {
          this.tableDataEmployee.push(element);
        });
        setTimeout(() => {
          if (this.tableDataEmployee.length > 0) {
            this.validateEmployeeSession();
          } else {
            Swal.fire(
              'Por favor, aguarda hasta que se complete el registro de los empleados',
              '',
              'info'
            )
          }
        }, 500);
      }
    });
  }

  async validateEmployeeSession() {
    await Swal.fire({
      title: "Ingresa tu pin de empleado",
      icon: 'warning',
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showLoaderOnConfirm: true,
      confirmButtonText: 'Continuar',
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      console.log("result: ", result);
      if (result.isConfirmed) {
        if (result.value !== '') {
          console.log("tableDataEmployee", this.tableDataEmployee);
          const employee = this.tableDataEmployee.filter(item => Number(item.pin) === Number(result.value))
          console.log("employee", employee);

          if (employee && employee.length > 0) {
            const [data] = employee;
            this.dataEmployee = data;
            localStorage.setItem('session_employee', JSON.stringify(data));
            Swal.fire(
              'Bienvenido',
              '',
              'success'
            )
          } else {
            localStorage.removeItem('session_employee');
            Swal.fire(
              'Pin invalido',
              '',
              'info'
            );
            setTimeout(() => {
              this.validateEmployeeSession();
            }, 3000);
          }
        } else {
          Swal.fire(
            'Ingresa un pin',
            '',
            'info'
          );
          setTimeout(() => {
            this.validateEmployeeSession();
          }, 3000);
        }
      }
    })
  }

  async showLoading() {
    Swal.fire({
      icon: 'info',
      title: 'Espera por favor...',
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      timerProgressBar: true,
      timer: 8e3
    })
  }

  async makeACut() {
    if (this.model.cash_payment >= 0 && this.model.n1co_payment >= 0 && this.model.ianos_payment >= 0) {
      await Swal.fire({
        title: `Estas seguro de querer realizar el corte de caja?`,
        icon: 'warning',
        showDenyButton: true,
        confirmButtonText: 'Continuar',
        denyButtonText: `Cancelar`,
      }).then((result) => {
        if (result.isConfirmed) {
          this.showLoading();
          const commerce_money = this.dataCommerce.commerce_money || 0;
          const commerce_last_money = this.dataCommerce.commerce_last_money || 0;
          this.dataCommerce.commerce_last_money = commerce_money;
          this.dataCommerce.commerce_money = 0;
          this.dataCommerce.last_daily_cut = new Date();
          const employee = JSON.parse(localStorage.getItem('session_employee'));
          this.dataCommerce.last_daily_cut_by = employee.name;
          this.dataCommerce.cash_flow_statement = false;

          this.firestore.update("commerces", this.dataCommerce.uid, { ...this.dataCommerce }).then(res => {
            const data = {
              uid: "",
              commerce_money: commerce_money,
              commerce_last_money: commerce_last_money,
              last_daily_cut: this.dataCommerce.last_daily_cut,
              last_daily_cut_by: this.dataCommerce.last_daily_cut_by,
              last_daily_cut_by_uid: employee.id,
              details_paymnet: { ...this.model }
            };
            this.firestore.saveSubcollection("commerces", this.dataCommerce.uid, "daily_cutting_log", data.uid, { ...data }).then(res => {
            });
            const dataCut = {
              uid: "",
              last_daily_opening: new Date(),
              last_daily_opening_by: employee.name,
              cash_flow_statement: false,
              last_daily_opening_by_uid: employee.id
            };
            this.firestore.saveSubcollection("commerces", this.dataCommerce.uid, "daily_opening_log", dataCut.uid, { ...dataCut }).then(res => {
              setTimeout(() => {
                Swal.close();
                Swal.fire(
                  "Listo",
                  "Corte de caja registrado.",
                  'success'
                )
                this.modalService.dismissAll();
              }, 4500);
            });
          });
          this.makeSaleReports();
        }
      });
    } else {
      Swal.fire(
        "Por favor",
        "Verifica los campos",
        'warning'
      )
    }
  }

  async makeSaleReports() {
    this.auth.getUser2().then(async (res0: any) => {
      // console.log("getUser2: ",res0)

      this.user = res0;

      if (res0.hasOwnProperty('quick_quote')) {
        this.messageInvoiceEMMI.contacts = res0.quick_quote.contacts;
        this.messageInvoiceEMMI.pk_branch = res0.quick_quote.pk_branch;
        this.messageInvoiceEMMI.pk_customer = res0.quick_quote.pk_customer;
        this.messageInvoiceEMMI.pk_emmi_company = res0.quick_quote.pk_emmi_company;
        this.messageInvoiceEMMI.pk_seller = res0.quick_quote.pk_seller;
        this.messageInvoiceEMMI.pk_payment_condition = res0.quick_quote.pk_payment_condition;
        this.messageInvoiceEMMI.pk_ofert_time = res0.quick_quote.pk_ofert_time;
        this.messageInvoiceEMMI.pk_kind_invoice = res0.quick_quote.pk_kind_invoice;
        this.messageInvoiceEMMI.pk_user = res0.quick_quote.pk_user;
        this.messageInvoiceEMMI.pk_quote_kind = res0.quick_quote.pk_quote_kind;
        this.messageInvoiceEMMI.pk_storage = res0.quick_quote.pk_storage;
        this.messageInvoiceEMMI.pk_method_payment = res0.quick_quote.pk_method_payment;


        if (this.salesOfTheDayCash.items.length > 0) {
          const dataConcat = this.salesOfTheDayCash.items;
          this.itemsNotEMMIRelation.length = 0;

          await this.validateProductEMMI(dataConcat).then(async (res: any) => {
            const cashData = {
              products_not_relation: this.itemsNotEMMIRelation,
              ...this.salesOfTheDayCash,
              ...this.messageInvoiceEMMI,
            };
            // console.log("res", res);
            if (res.length == 0) {
              let indexDate1 = new Date();

              // console.log('salesOfTheDayCash', this.salesOfTheDayCash);
              // Factura de ordenes - Efectivo
              this.messageInvoiceEMMI.pk_payment_condition = this.messageInvoiceEMMI.pk_method_payment.pk_cash;
              this.messageInvoiceEMMI.quote_comments = "Reporte de venta | Fecha: " + indexDate1.toLocaleDateString() +
                " | Metodo de pago: Efectivo | Sucursal: " + this.CommerceName;

              cashData.pk_payment_condition = this.messageInvoiceEMMI.pk_method_payment.pk_cash;
              cashData.quote_comments = "Reporte de venta | Fecha: " + indexDate1.toLocaleDateString() +
                " | Metodo de pago: Efectivo | Sucursal: " + this.CommerceName;
              let data = {
                user_uid: this.user.uid,
                user_email: this.user.email,
                commerce_uid: this.CommerceUid,
                commerce_name: this.CommerceName,
                date_invoice: indexDate1,
                type_invoice: 'Efectivo',
                success: false,
                ...cashData,
                items: this.salesOfTheDayCash.items,
              }
              await this.commonServices.makeSaleReports(this.salesOfTheDayCash, this.messageInvoiceEMMI, this.user, this.allProducts, data);
              this.sendSalesOfTheDayCard();
            } else {
              let indexDate1 = new Date();
              this.messageInvoiceEMMI.pk_payment_condition = this.messageInvoiceEMMI.pk_method_payment.pk_cash;
              this.messageInvoiceEMMI.quote_comments = "Reporte de venta | Fecha: " + indexDate1.toLocaleDateString() +
                " | Metodo de pago: Efectivo | Sucursal: " + this.CommerceName;

              cashData.pk_payment_condition = this.messageInvoiceEMMI.pk_method_payment.pk_cash;
              cashData.quote_comments = "Reporte de venta | Fecha: " + indexDate1.toLocaleDateString() +
                " | Metodo de pago: Efectivo | Sucursal: " + this.CommerceName;

              console.log("salesOfTheDayCash", this.salesOfTheDayCash);

              let data = {
                user_uid: this.user.uid,
                user_email: this.user.email,
                commerce_uid: this.CommerceUid,
                commerce_name: this.CommerceName,
                date_invoice: indexDate1,
                type_invoice: 'Efectivo',
                success: false,
                ...cashData,
                items: this.salesOfTheDayCash.items,
              }

              this.unSentInvoices(data);
              this.sendSalesOfTheDayCard();
            }
          })
        } else {
          this.sendSalesOfTheDayCard();
        }

      }
    });
  }

  async sendSalesOfTheDayCard() {
    if (this.salesOfTheDayCard.items.length > 0) {
      await setTimeout(async () => {
        const dataConcat = this.salesOfTheDayCard.items;
        this.itemsNotEMMIRelation.length = 0;

        await this.validateProductEMMI(dataConcat).then(async (res: any) => {
          // console.log("res", res);
          const cardData = {
            products_not_relation: this.itemsNotEMMIRelation,
            ...this.salesOfTheDayCard,
            ...this.messageInvoiceEMMI,
          };
          if (res.length == 0) {
            let indexDate1 = new Date();

            // console.log('salesOfTheDayCard', this.salesOfTheDayCard);
            // Factura de ordenes - Tarjeta App
            this.messageInvoiceEMMI.pk_payment_condition = this.messageInvoiceEMMI.pk_method_payment.pk_card;
            this.messageInvoiceEMMI.quote_comments = "Reporte de venta | Fecha: " + indexDate1.toLocaleDateString() +
              " | Metodo de pago: Tarjeta App | Sucursal: " + this.CommerceName;
            cardData.pk_payment_condition = this.messageInvoiceEMMI.pk_method_payment.pk_card;
            cardData.quote_comments = "Reporte de venta | Fecha: " + indexDate1.toLocaleDateString() +
              " | Metodo de pago: Tarjeta App | Sucursal: " + this.CommerceName;

            let data = {
              user_uid: this.user.uid,
              user_email: this.user.email,
              commerce_uid: this.CommerceUid,
              commerce_name: this.CommerceName,
              date_invoice: indexDate1,
              type_invoice: 'Tarjeta App',
              success: false,
              ...cardData,
              items: this.salesOfTheDayCard.items,
            }

            await this.commonServices.makeSaleReports(this.salesOfTheDayCard, this.messageInvoiceEMMI, this.user, this.allProducts, data);
            this.sendSalesOfTheDayN1co();

          } else {
            let indexDate1 = new Date();
            this.messageInvoiceEMMI.pk_payment_condition = this.messageInvoiceEMMI.pk_method_payment.pk_card;
            this.messageInvoiceEMMI.quote_comments = "Reporte de venta | Fecha: " + indexDate1.toLocaleDateString() +
              " | Metodo de pago: Tarjeta App | Sucursal: " + this.CommerceName;
            cardData.pk_payment_condition = this.messageInvoiceEMMI.pk_method_payment.pk_card;
            cardData.quote_comments = "Reporte de venta | Fecha: " + indexDate1.toLocaleDateString() +
              " | Metodo de pago: Tarjeta App | Sucursal: " + this.CommerceName;

            // console.log("salesOfTheDayCard", this.salesOfTheDayCard);

            let data = {
              user_uid: this.user.uid,
              user_email: this.user.email,
              commerce_uid: this.CommerceUid,
              commerce_name: this.CommerceName,
              date_invoice: indexDate1,
              type_invoice: 'Tarjeta App',
              success: false,
              ...cardData,
              items: this.salesOfTheDayCard.items,
            }

            this.unSentInvoices(data);
            this.sendSalesOfTheDayN1co();
          }
        })
      }, 2000);
    } else {
      this.sendSalesOfTheDayN1co();
    }
  }

  async sendSalesOfTheDayN1co() {
    if (this.salesOfTheDayN1co.items.length > 0) {
      await setTimeout(async () => {
        const dataConcat = this.salesOfTheDayN1co.items;
        this.itemsNotEMMIRelation.length = 0;

        await this.validateProductEMMI(dataConcat).then(async (res: any) => {
          // console.log("res", res);
          const n1coData = {
            products_not_relation: this.itemsNotEMMIRelation,
            ...this.salesOfTheDayN1co,
            ...this.messageInvoiceEMMI,
          };

          if (res.length == 0) {
            let indexDate1 = new Date();

            // console.log('salesOfTheDayCard', this.salesOfTheDayCard);
            // Factura de ordenes - Tarjeta N1co
            this.messageInvoiceEMMI.pk_payment_condition = this.messageInvoiceEMMI.pk_method_payment.pk_card;
            this.messageInvoiceEMMI.quote_comments = "Reporte de venta | Fecha: " + indexDate1.toLocaleDateString() +
              " | Metodo de pago: Tarjeta N1co | Sucursal: " + this.CommerceName;

            n1coData.pk_payment_condition = this.messageInvoiceEMMI.pk_method_payment.pk_card;
            n1coData.quote_comments = "Reporte de venta | Fecha: " + indexDate1.toLocaleDateString() +
              " | Metodo de pago: Tarjeta N1co | Sucursal: " + this.CommerceName;

            let data = {
              user_uid: this.user.uid,
              user_email: this.user.email,
              commerce_uid: this.CommerceUid,
              commerce_name: this.CommerceName,
              date_invoice: indexDate1,
              type_invoice: 'Tarjeta N1co',
              success: false,
              ...n1coData,
              items: this.salesOfTheDayN1co.items,
            }

            await this.commonServices.makeSaleReports(this.salesOfTheDayN1co, this.messageInvoiceEMMI, this.user, this.allProducts, data);

          } else {
            let indexDate1 = new Date();
            this.messageInvoiceEMMI.pk_payment_condition = this.messageInvoiceEMMI.pk_method_payment.pk_card;
            this.messageInvoiceEMMI.quote_comments = "Reporte de venta | Fecha: " + indexDate1.toLocaleDateString() +
              " | Metodo de pago: Tarjeta N1co | Sucursal: " + this.CommerceName;

            n1coData.pk_payment_condition = this.messageInvoiceEMMI.pk_method_payment.pk_card;
            n1coData.quote_comments = "Reporte de venta | Fecha: " + indexDate1.toLocaleDateString() +
              " | Metodo de pago: Tarjeta N1co | Sucursal: " + this.CommerceName;

            // console.log("salesOfTheDayN1co", this.salesOfTheDayN1co);

            let data = {
              user_uid: this.user.uid,
              user_email: this.user.email,
              commerce_uid: this.CommerceUid,
              commerce_name: this.CommerceName,
              date_invoice: indexDate1,
              type_invoice: 'Tarjeta N1co',
              success: false,
              ...n1coData,
              items: this.salesOfTheDayN1co.items,
            }

            this.unSentInvoices(data);
          }
        })
      }, 2000);
    }
  }

  unSentInvoices(data) {
    const log = {
      uid: '',
      created_at: new Date(),
      ...data
    };
    log.uid = this.firestore.getUid(log);
    console.log("unSentInvoices", log);

    // Guarda los reportes de ventas que no se enviaron a EMMI
    this.firestore.save("unsent_invoices", { ...log }).then(save => { });
  }

  async validateProductEMMI(salesOfTheDay) {
    return new Promise(async (resolve) => {
      await salesOfTheDay.forEach(async (order, index) => {
        let [data_products] = this.allProducts.filter(items => items.uid === order.uid);
        // await this.firestore.getOneWatcher('products', order.uid).subscribe(async data_products => {
        if ((!data_products.product_emmi ||
          (
            typeof data_products.product_emmi === 'object' &&
            !Array.isArray(data_products.product_emmi) &&
            Object.keys(data_products.product_emmi).length === 0 ||
            Array.isArray(data_products.product_emmi) &&
            data_products.product_emmi.length === 0
          )) && data_products.uid_product_yelou
        ) {
          let [filterIndex] = await this.itemsNotEMMIRelation.filter(item => item.uid_product_yelou === data_products.uid_product_yelou);

          if (filterIndex === undefined) {
            this.itemsNotEMMIRelation.push(data_products);
          }
        }

        if (index === (salesOfTheDay.length - 1)) {
          resolve(this.itemsNotEMMIRelation);
        }
        // });
      });
    });
  }

  async loadSalesOfTheDay(orders) {
    // Obtiene todos los productos de Firestore
    await this.firestore.getAll('products').subscribe(async data_products => {
      this.allProducts = data_products;

      // Función para inicializar los datos de ventas
      const initializeSalesData = () => ({
        totalFlete: 0,
        totalFleteOutIva: 0,
        totalFleteOnlyIva: 0,
        quantityFlete: 0,
        commentFlete: "",
        totalExtras: 0,
        totalExtrasOutIva: 0,
        totalExtrasOnlyIva: 0,
        quantityExtras: 0,
        commentExtras: "",
        items: [],
        extras: []
      });

      // Inicializa datos de ventas para efectivo y tarjeta
      this.salesOfTheDayCash = initializeSalesData();
      this.salesOfTheDayCard = initializeSalesData();
      this.salesOfTheDayN1co = initializeSalesData();

      // Función para procesar extras
      const processExtras = (extras, salesData, quantity) => {
        extras.forEach(productExtras => {
          // salesData.quantityExtras++;
          // salesData.totalExtras += Number(productExtras.price);
          // salesData.commentExtras += ` \n ${productExtras.optionRes} | ${productExtras.subOptionRes} | $${Number(productExtras.price).toFixed(2)}`;

          const ivaExtras = Number(productExtras.price) - (Number(productExtras.price) / 1.13);
          const filterIndexExtras = salesData.extras.findIndex(i => i.name === `${productExtras.optionRes} | ${productExtras.subOptionRes}` && i.price === this.toFixed((Number(productExtras.price)), 2));

          if (filterIndexExtras > -1) {
            const filteredExtras = salesData.extras[filterIndexExtras];

            // filteredExtras.quantity += salesData.quantityExtras;
            filteredExtras.quantity += quantity;
            filteredExtras.total += this.toFixed((Number(productExtras.price) * quantity), 2);
            filteredExtras.total_without_iva += this.toFixed(((Number(productExtras.price) * quantity) - (ivaExtras * quantity)), 2);
            filteredExtras.total_iva += this.toFixed((ivaExtras * quantity), 2);
          } else {

            salesData.extras.push({
              name: `${productExtras.optionRes} | ${productExtras.subOptionRes}`,
              uid: `${productExtras.optionID}:${productExtras.subOptionID}`,
              quantity: quantity,

              price: this.toFixed((Number(productExtras.price)), 2),
              price_iva: this.toFixed(ivaExtras, 2),
              price_without_iva: this.toFixed((Number(productExtras.price) - ivaExtras), 2),
              total: this.toFixed((Number(productExtras.price) * quantity), 2),
              total_without_iva: this.toFixed(((Number(productExtras.price) * quantity) - (ivaExtras * quantity)), 2),
              total_iva: this.toFixed((ivaExtras * quantity), 2)
            })
          }
        });
      };

      // Función para procesar un producto
      const processProduct = async (product, salesData, item) => {
        let [data_products1] = this.allProducts.filter(productItem => productItem.uid === product.uid);
        let [data_products] = this.allProducts.filter(productItem => productItem.uid === (data_products1?.uid_product_yelou || data_products1.uid));

        const iva = Number(product.price) - (Number(product.price) / 1.13);
        const iva_total = iva * product.quantity;
        const filterIndex = salesData.items.findIndex(i => i.uid === product.uid && i.is_tasting === item.is_tasting && i.is_expired_product === item.is_expired_product && i.is_member === item.is_member && i.price === this.toFixed(product.price, 2));

        if (filterIndex > -1 && (data_products.is_dynamic_product === undefined || !data_products.is_dynamic_product)) {
          const filteredProduct = salesData.items[filterIndex];
          filteredProduct.quantity += product.quantity;
          if (!item.is_tasting && !item.is_expired_product) {
            filteredProduct.sum += this.toFixed((product.quantity * product.price), 2);
            filteredProduct.subtotal += this.toFixed((product.price * product.quantity), 2);
            filteredProduct.total += this.toFixed((product.price * product.quantity), 2);
            filteredProduct.total_without_iva += this.toFixed(((product.price * product.quantity) - iva_total), 2);
            filteredProduct.total_iva += this.toFixed(iva_total, 2);
          }
        } else {
          salesData.items.push({
            name: product.name,
            uid: product.uid,
            quantity: product.quantity,
            price: (item.is_tasting || item.is_expired_product) ? 0 : this.toFixed(product.price, 2),
            price_iva: (item.is_tasting || item.is_expired_product) ? 0 : this.toFixed(iva, 2),
            price_without_iva: (item.is_tasting || item.is_expired_product) ? 0 : this.toFixed((product.price - iva), 2),
            subtotal: (item.is_tasting || item.is_expired_product) ? 0 : this.toFixed((product.price * product.quantity), 2),
            total: (item.is_tasting || item.is_expired_product) ? 0 : this.toFixed((product.price * product.quantity), 2),
            total_without_iva: (item.is_tasting || item.is_expired_product) ? 0 : this.toFixed(((product.price * product.quantity) - iva_total), 2),
            total_iva: (item.is_tasting || item.is_expired_product) ? 0 : this.toFixed(iva_total, 2),
            sum: product.quantity * ((item.is_tasting || item.is_expired_product) ? 0 : product.price),
            is_card: item.is_card || false,
            is_n1co_card: item.is_n1co_card || false,
            is_member: item.is_member || false,
            is_tasting: item.is_tasting || false,
            is_expired_product: item.is_expired_product || false,
            category_uid: data_products?.category_uid || '',
            commerce_uid: data_products?.commerce_uid || '',
            extras: product.extras,
          });
        }

        if (product.extras.length > 0) {
          if (data_products.is_dynamic_product === undefined || !data_products.is_dynamic_product) {
            processExtras(product.extras, salesData, product.quantity);
          } else {
            await product.extras.forEach(async (element) => {
              let optionID = element.optionID;
              let subOptionID = element.subOptionID;
              let condition = data_products.extra_options && data_products.extra_options[optionID];
              if (condition && (!condition.hasOwnProperty('activeEMMI') || condition['activeEMMI'] === undefined || condition['activeEMMI'] === false)) {
                if (condition.subOptions && condition.subOptions[subOptionID] && condition.subOptions[subOptionID]['is_default'] === true) {
                  processExtras([element], salesData, product.quantity);
                }
              } else if (condition && condition.hasOwnProperty('activeEMMI') && condition['activeEMMI'] !== undefined && condition['activeEMMI'] === true) {
                if (condition.subOptions && condition.subOptions[subOptionID] && condition.subOptions[subOptionID]['is_default'] === true) {
                  processExtras([element], salesData, product.quantity);
                }
              }
            });
          }
        }
      };

      // Función para procesar un artículo
      const processItem = async (item, salesData) => {
        if (item.is_delivery) {
          salesData.quantityFlete++;
          salesData.totalFlete += Number(item.subtotalForRide);
          salesData.commentFlete += ` \n ${item.uid} | $${Number(item.subtotalForRide).toFixed(2)}`;
        }

        // Procesa cada producto dentro del artículo
        for (const product of item.items) {
          await processProduct(product, salesData, item);
        }
      };

      // Procesa todas las órdenes
      for (const item of orders) {
        if (item.is_card && !item.is_n1co_card) {
          await processItem(item, this.salesOfTheDayCard);
        } else if (!item.is_card && item.is_n1co_card) {
          await processItem(item, this.salesOfTheDayN1co);
        } else {
          await processItem(item, this.salesOfTheDayCash);
        }
      }

      // Permite tiempo para cualquier operación pendiente antes de continuar
      setTimeout(() => {
        // Aquí puedes agregar código para trabajar con salesOfTheDayCash y salesOfTheDayCard si es necesario
        // console.log('salesOfTheDayCash', this.salesOfTheDayCash);
        // console.log('salesOfTheDayN1co', this.salesOfTheDayN1co);
        // console.log('salesOfTheDayCard', this.salesOfTheDayCard);
      }, 2500);
    });
  }

  toFixed(num, fixed) {
    return Number(num.toFixed(4));
  }

  TopCategoriesSales() {
    this.firestore.getWhere2('orders', 'manager_uid', this.user.uid, 'orders_status_uid', 'Entregada').subscribe(orders => { //se obtiene los datos de las ordenes del comercio
      // console.log("orders: ",orders);
      if (orders) {
        this.firestore.getWhere('commerces', 'manager_user_uid', this.user.uid).subscribe(commerce => { // se obtienen los datos del comercio
          // console.log("commerce: ",commerce);
          commerce.forEach(element0 => {
            this.firestore.getWhere('categories', 'commerce_uid', element0.uid).subscribe(categories => { // se obtienen las categorias del comercio
              // console.log("categories: ", categories);
              this.data_categories.length = 0;
              this.data_products.length = 0;

              categories.forEach((element2, index2) => {
                this.data_categories.push({
                  id: element2.uid,
                  name: element2.name
                })
                this.firestore.getWhere('products', 'category_uid', element2.uid).subscribe(data_products => { // se obtienen los productos del comercio
                  // console.log("data_products: ", data_products);
                  data_products.forEach((element3, index3) => {
                    this.data_products.push({
                      id: element3.uid,
                      uid_product_yelou: element3.uid_product_yelou,
                      name: element3.name,
                      id_categorie: element2.uid,
                      name_categorie: element2.name
                    })
                    if (index2 === (categories.length - 1) && index3 === (data_products.length - 1)) {
                      let products = [];
                      orders.forEach((element, index) => {
                        if (element.total > 0) {
                          element.items.forEach(element1 => {
                            // console.log("products.items: ",element1);
                            element1.caregory_name = this.filterProductName(element1);
                            element1.caregory_id = this.filterProductId(element1);
                            products.push(element1);

                            if (index === (orders.length - 1)) {
                              // console.log("products: ",products);
                              const categoryByUid = products.reduce((obj, product, index4) => {
                                // console.log("product.uid: ",product);
                                const caregory_name = product.caregory_name;
                                if (!obj[caregory_name]) {
                                  obj[caregory_name] = [];
                                }
                                obj[caregory_name].push({
                                  sales: product.subtotal,
                                  price: product.price,
                                  quantity: product.quantity,
                                });
                                return obj;
                              }, {});
                              // console.log("categoryByUid: ", categoryByUid);
                              const arr_categories = Object.entries(categoryByUid).map(([key, value]) => {
                                const items = value as { sales: number, price: number, quantity: number }[]; // afirmación de tipo
                                return { category: key, sales: items.reduce((acc, cur) => acc + cur.sales, 0), quantity: items.reduce((acc, cur) => acc + cur.quantity, 0) };
                              });
                              arr_categories.sort((a, b) => b.quantity - a.quantity);
                              // console.log("arr_categories: ",arr_categories);
                              this.data_sale_categories = arr_categories;

                              const productByUid = products.reduce((obj, product) => {
                                // console.log("product.uid: ",product);
                                const name = product.name;
                                if (!obj[name]) {
                                  obj[name] = [];
                                }
                                obj[name].push({
                                  sales: product.subtotal,
                                  price: product.price,
                                  quantity: product.quantity,
                                });
                                return obj;
                              }, {});
                              // console.log("productByUid: ", productByUid);
                              const arr_products = Object.entries(productByUid).map(([key, value]) => {
                                const items = value as { sales: number, price: number, quantity: number }[]; // afirmación de tipo
                                return { category: key, price: items[0].price, sales: items.reduce((acc, cur) => acc + cur.sales, 0), quantity: items.reduce((acc, cur) => acc + cur.quantity, 0) };
                              });
                              arr_products.sort((a, b) => b.quantity - a.quantity);
                              // console.log("arr_products: ",arr_products);
                              this.data_sale_products = arr_products;
                            }
                          });
                        }
                      });
                    }
                  });
                });
              });
            });
          });
        });
      }
    });
  }

  filterProductName(item) {
    // console.log("item.name: ",item);
    const data: any = this.data_products.filter(items => item.uid === items.id);
    // console.log("data: ",data);
    if (data.length === 0 || data === undefined) {
      const data: any = this.data_products.filter(items => item.uid === items.uid_product_yelou);
      if (data.length !== 0) {
        return data[0].name_categorie;
      }
    } else {
      return data[0].name_categorie;
    }
  }

  filterProductId(item) {
    // console.log("item.name: ",item);
    const data: any = this.data_products.filter(items => item.uid === items.id);
    // console.log("data: ",data);
    if (data.length === 0 || data === undefined) {
      const data: any = this.data_products.filter(items => item.uid === items.uid_product_yelou);
      if (data.length !== 0) {
        return data[0].id_categorie;
      }
    } else {
      return data[0].id_categorie;
    }
  }

  formatDate(date) {
    var day = new Date(date.seconds * 1000);
    var options: any = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: true };
    var format = day.toLocaleString('es-ES', options);
    return format;
  }

  async SearchByDate() {
    let indexDate1 = new Date(this.selectedDate1.year, this.selectedDate1.month - 1, this.selectedDate1.day, 0, 0, 0);
    let indexDate2 = new Date(this.selectedDate2.year, this.selectedDate2.month - 1, this.selectedDate2.day, 23, 59, 59);

    await this.firestore.getWhere4('orders', 'manager_uid', this.user.uid, 'orders_status_uid', 'Entregada', indexDate1, indexDate2).subscribe((res2) => {
      let dataChart_labels: any = [];
      let countOrders = 0;
      let countOrdersSales = 0;
      this.refresh = false;
      this.show_message_no_data = false;
      this.count_orders_date = 0;
      this.count_orders_complete_date = 0;
      let dataChart = [];
      dataChart.length = 0;

      setTimeout(() => {
        if (res2.length !== 0) {
          // console.log(" res2", res2);
          let groupedDates = {};
          countOrders += res2.length;

          res2.forEach((orders_, index) => {
            let initTime = new Date(Date.UTC(70, 0, 1, 0, 0, 0, 0));
            initTime.setSeconds(orders_['created_at']['seconds']);
            countOrdersSales += orders_.total;

            let format = initTime.toDateString().split(" ");
            let format_;
            format_ = format[0] + " " + format[1] + " " + format[2] + " " + format[3];

            if (!groupedDates[format_]) {
              groupedDates[format_] = { x: format_, y: 0, count: 0 };
            }

            groupedDates[format_].y += 1;
            groupedDates[format_].count += Number(orders_.total);
            groupedDates[format_].z = "$ " + (groupedDates[format_].count).toFixed(2);

            if (index === (res2.length - 1)) {
              let day_format: any = new Date(indexDate1.getFullYear(), (indexDate1.getMonth()), indexDate1.getDate(), 23, 59, 59);
              let hour_format_1: any = new Date(indexDate2.getFullYear(), (indexDate2.getMonth()), indexDate2.getDate(), 0, 0, 0);
              let options;

              options = { year: 'numeric', month: 'short', day: 'numeric' };

              let format_1 = day_format.toLocaleDateString('es-ES', options);
              let format_2 = hour_format_1.toLocaleDateString('es-ES', options);
              this.date_from = format_1;
              this.date_up_to = format_2;
              // console.log("groupedDates: ", groupedDates);

              // Agregar el objeto al array dataChart_labels sin modificarlo
              let dataChartEntry = {
                name: "# de pedidos",
                data: Object.values(groupedDates) // Obtener los valores del objeto groupedDates
              };
              dataChart_labels.push(dataChartEntry);

              let longestArray = 0;
              let longestArray_: any;
              dataChart_labels.forEach(element => {
                if (element.data.length > longestArray) {
                  longestArray = element.data.length;
                  longestArray_ = element;
                }
              });
              // console.log("longestArray_: ", longestArray_);

              dataChart_labels.forEach(element1 => {
                longestArray_.data.forEach(element3 => {
                  if (element1.name !== longestArray_.name) {
                    let prueba = element1.data.find(item => item.x === element3.x);
                    // console.log("prueba: ", prueba);
                    if (!prueba) {
                      element1.data.push({ x: element3.x, y: 0, count: 0 });
                    }
                  }
                });
                element1.data = element1.data.sort(function (a, b) {
                  var keyA = new Date(a.x), keyB = new Date(b.x);
                  if (keyA > keyB) return 1;
                  if (keyA < keyB) return -1;
                  return 0;
                });
              });
              // console.log("dataChart_labels: ", dataChart_labels);
              this.count_orders_date = countOrders;
              this.count_orders_complete_date = Number(this.ToFixed(countOrdersSales));
              this.refresh = true;

              this.showChart(this.obj, dataChart_labels);
            }
          });

        } else {
          let longestArray = 0;
          let longestArray_: any;
          dataChart_labels.forEach(element => {
            if (element.data.length > longestArray) {
              longestArray = element.data.length;
              longestArray_ = element
            }
          });
          // console.log("longestArray_: ", longestArray_);

          dataChart_labels.forEach(element1 => {
            longestArray_.data.forEach(element3 => {
              if (element1.name !== longestArray_.name) {
                let prueba = element1.data.find(item => item.x === element3.x);
                // console.log("prueba: ", prueba);
                if (!prueba) {
                  element1.data.push({ x: element3.x, y: 0, count: 0 });
                }
              }
            });
            element1.data = element1.data.sort(function (a, b) {
              var keyA = new Date(a.x), keyB = new Date(b.x);
              if (keyA > keyB) return 1;
              if (keyA < keyB) return -1;
              return 0;
            });
          });
          // console.log("dataChart_labels~: ", dataChart_labels);
          this.count_orders_date = countOrders;
          this.count_orders_complete_date = Number(this.ToFixed(countOrdersSales));
          this.refresh = true;

          this.showChart(this.obj, dataChart_labels);
        }
      }, 300);
    });
  }

  getProfitPerMonth() {
    let date;
    date = new Date();
    let month = date.getMonth();
    let year = date.getFullYear();
    let day = date.getDate();
    let indexDate1;
    // indexDate1 =  new Date(Number(year), Number(month) - 1, Number(day) + 1);
    indexDate1 = new Date(Number(year), Number(month), Number(day) - 30, 0, 0);
    const options = { year: 'numeric', month: 'short', day: 'numeric' };

    this.profitsPerMonth_date = indexDate1.toLocaleDateString('es-ES', options) + " - " + date.toLocaleDateString('es-ES', options);

    this.firestore.getWhere3('orders', 'manager_uid', this.user.uid, 'orders_status_uid', 'Entregada', indexDate1, "").subscribe(data => {
      data.forEach((element) => {
        // console.log(element.amount);
        this.profitsPerMonth += Number(element.total);
      });
      this.profitsPerMonth = Number(this.profitsPerMonth.toFixed(2));
      // console.log(`getProfitPerMonth = ${this.profitsPerMonth}`);
    });
    // console.log(`getProfitPerMonth = ${this.profitsPerMonth}`);
  }

  getProfitPerDay() {
    let date = new Date();

    let month = date.getMonth();
    let year = date.getFullYear();
    let day = date.getDate();
    let start;
    let end
    start = new Date(Number(year), Number(month), Number(day), 23, 59);
    end = new Date(Number(year), Number(month), Number(day), 0, 0);
    // console.log("start: ",start);
    // console.log("end: ",end);
    const options = { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' };

    this.profitsPerDay_date = end.toLocaleDateString('es-ES', options) + " - " + start.toLocaleDateString('es-ES', options);

    this.firestore.getWhere4('orders', 'manager_uid', this.user.uid, 'orders_status_uid', 'Entregada', end, start).subscribe(data => {
      // console.log('profitsPerDay: ', data);
      data.forEach(element => {
        if (!element.is_tasting && !element.is_expired_product) {
          this.profitsPerDay += Number(element.total);
        }
      });

      this.profitsPerDay = Number(this.profitsPerDay.toFixed(2));
      setTimeout(() => {
        // console.log(`salesOfTheDay`, this.salesOfTheDay);
        this.loadSalesOfTheDay(data);
      }, 500);
      // console.log(`getProfitPerDay = ${this.profitsPerDay}`);
    });
  }

  getProfitPerWeek() {
    let date = new Date();

    let month = date.getMonth();
    let year = date.getFullYear();
    let day = date.getDate();
    let start;
    let end
    start = new Date(Number(year), Number(month), Number(day), 23, 59);
    end = new Date(Number(year), Number(month), Number(day) - 7, 0, 0);
    // console.log("start: ",start);
    // console.log("end: ",end);
    const options = { year: 'numeric', month: 'short', day: 'numeric' };

    this.profitsPerWeek_date = end.toLocaleDateString('es-ES', options) + " - " + start.toLocaleDateString('es-ES', options);

    this.firestore.getWhere4('orders', 'manager_uid', this.user.uid, 'orders_status_uid', 'Entregada', end, start).subscribe(data => {
      // console.log('profitsPerWeek: ', data);
      data.forEach((element) => {
        this.profitsPerWeek += Number(element.total);
      });
      this.profitsPerWeek = Number(this.profitsPerWeek.toFixed(2));
      // console.log(`getProfitPerDay = ${this.profitsPerWeek}`);
    });
  }

  getCommerces() {
    this.firestore.getWhere('commerces', 'manager_user_uid', this.user.uid).subscribe(res => {
      let sched = [];
      let date = new Date();
      let commerce = [];
      this.prueba = [];
      this.schedule = [];
      // console.log(res);
      res.forEach((element: any) => {
        // console.log(element);
        if (element.schedule != undefined && Array.isArray(element.schedule)) {
          if (element.close_automatically) {
            sched = element.schedule;
            commerce = element;
            this.schedule.push(sched.filter(item => item.index == date.getDay()));

            this.commerces_names.push(element.name);
            this.commerces_status.push(element.is_available);
            this.day = this.schedule[0][0].day

            this.prueba.push(this.schedule[0]);
            // console.log(`COMMERCE NAME ${element.name}`);
            // console.log(this.schedule);
            // console.log(sched);
            setInterval(this.clock, 1000);
          } else {
            // console.log("Schedule is not available");
          }
        }

        this.CommerceName = element.name;
        this.CommerceUid = element.uid;

      });
      const comerce = res.find(item => item.isYellowGoAffiliate === true);
      // console.log("comerce: ",comerce)
      if (comerce && comerce.isYellowGoAffiliate) {
        this.isYellowGoAffiliate = true;
      } else {
        this.isYellowGoAffiliate = false;
      }
      this.data_commerces = res;
      // console.log(commerce);
    });
  }

  clock() {
    let time = new Date();
    let hour = time.getHours();
    let min = time.getMinutes();
    let sec = time.getSeconds();

    let currentTime;
    currentTime = ((hour < 10) ? "0" + hour : hour) + ":" + ((min < 10) ? "0" + min : min) + ":" + ((sec < 10) ? "0" + sec : sec);

    if (document.getElementById("h4")) {
      document.getElementById("h4").innerHTML = currentTime;
    }
  }

  ToFixed(number: number) {
    return Number(number).toFixed(2);
  }

  changedTypeTopSales(type) {
    switch (type) {
      case 1:
        this.btn_top_sales.categories.class = "btn btn-primary";
        this.btn_top_sales.categories.active = true;
        this.btn_top_sales.products.class = "btn btn-outline-primary";
        this.btn_top_sales.products.active = false;
        break;
      case 2:
        this.btn_top_sales.categories.class = "btn btn-outline-primary";
        this.btn_top_sales.categories.active = false;
        this.btn_top_sales.products.class = "btn btn-primary";
        this.btn_top_sales.products.active = true;
        break;
      default:
        break;
    }
  }

  async changedTypeSearch(type) {
    const today = this.calendar.getToday();
    let num_days;
    let limit_day;
    let indexDate1;
    let indexDate2;

    switch (type) {
      case 1:
        this.btn_date.today.class = "btn btn-primary";
        this.btn_date.week.class = "btn btn-outline-primary";
        this.btn_date.month.class = "btn btn-outline-primary";
        this.btn_date.year.class = "btn btn-outline-primary";
        num_days = this.calendar.getToday();
        limit_day = this.calendar.getToday();
        indexDate1 = new Date(num_days.year, num_days.month - 1, num_days.day, 0, 0);
        indexDate2 = new Date(limit_day.year, limit_day.month - 1, limit_day.day, 23, 59);

        break;
      case 2:
        this.btn_date.today.class = "btn btn-outline-primary";
        this.btn_date.week.class = "btn btn-primary";
        this.btn_date.month.class = "btn btn-outline-primary";
        this.btn_date.year.class = "btn btn-outline-primary";
        num_days = this.calendar.getPrev(today, 'd', 7);
        limit_day = this.calendar.getToday();
        indexDate1 = new Date(num_days.year, num_days.month - 1, num_days.day, 0, 0);
        indexDate2 = new Date(limit_day.year, limit_day.month - 1, limit_day.day, 23, 59);

        break;
      case 3:
        this.btn_date.today.class = "btn btn-outline-primary";
        this.btn_date.week.class = "btn btn-outline-primary";
        this.btn_date.month.class = "btn btn-primary";
        this.btn_date.year.class = "btn btn-outline-primary";
        num_days = this.calendar.getPrev(today, 'm', 1);
        limit_day = this.calendar.getToday();
        indexDate1 = new Date(num_days.year, num_days.month - 1, num_days.day, 0, 0);
        indexDate2 = new Date(limit_day.year, limit_day.month - 1, limit_day.day, 23, 59);

        break;
      case 4:
        this.btn_date.today.class = "btn btn-outline-primary";
        this.btn_date.week.class = "btn btn-outline-primary";
        this.btn_date.month.class = "btn btn-outline-primary";
        this.btn_date.year.class = "btn btn-primary";
        num_days = this.calendar.getPrev(today, 'y', 1);
        limit_day = this.calendar.getToday();
        indexDate1 = new Date(num_days.year, num_days.month - 1, num_days.day, 0, 0);
        indexDate2 = new Date(limit_day.year, limit_day.month - 1, limit_day.day, 23, 59);

        break;
      default:
        break;
    }
    // console.log("indexDate1", indexDate1);
    // console.log("indexDate2", indexDate2);
    await this.firestore.getWhere4('orders', 'manager_uid', this.user.uid, 'orders_status_uid', 'Entregada', indexDate1, indexDate2).subscribe((res2) => {
      let dataChart_labels: any = [];
      let countOrders = 0;
      let countOrdersSales = 0;
      this.refresh = false;
      this.show_message_no_data = false;
      this.count_orders_date = 0;
      this.count_orders_complete_date = 0;
      let dataChart = [];
      dataChart.length = 0;
      setTimeout(() => {
        if (res2.length !== 0) {
          // console.log(" res2", res2);
          let groupedDates = {};
          countOrders += res2.length;

          res2.forEach((orders_, index) => {
            let initTime = new Date(Date.UTC(70, 0, 1, 0, 0, 0, 0));
            initTime.setSeconds(orders_['created_at']['seconds']);
            countOrdersSales += orders_.total;

            let format = initTime.toDateString().split(" ");
            let format_;

            if (type === 4) {
              // format_ = format[1] + " " + format[2] + " " + format[3];
              format_ = format[1] + " " + "01" + " " + format[3];
            } else if (type === 3) {
              format_ = format[1] + " " + format[2] + " " + format[3];
            } else if (type === 1) {
              let otherFormatHour = initTime.getHours();
              format_ = format[1] + " " + format[2] + " " + format[3] + " " + ((otherFormatHour < 10) ? '0' + otherFormatHour + ':00:00' : otherFormatHour + ':00:00');
            } else {
              format_ = format[0] + " " + format[1] + " " + format[2] + " " + format[3];
            }

            if (!groupedDates[format_]) {
              groupedDates[format_] = { x: format_, y: 0, count: 0 };
            }

            groupedDates[format_].y += 1;
            groupedDates[format_].count += Number(orders_.total);
            groupedDates[format_].z = "$ " + (groupedDates[format_].count).toFixed(2);

            if (index === (res2.length - 1)) {
              let day_format: any = new Date(indexDate1.getFullYear(), (indexDate1.getMonth()), indexDate1.getDate(), 23, 59, 59);
              let hour_format_1: any = new Date(indexDate2.getFullYear(), (indexDate2.getMonth()), indexDate2.getDate(), 0, 0, 0);
              let options;

              if (type === 1) {
                options = { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' };
              } else {
                options = { year: 'numeric', month: 'short', day: 'numeric' };
              }

              let format_1 = day_format.toLocaleDateString('es-ES', options);
              let format_2 = hour_format_1.toLocaleDateString('es-ES', options);
              this.date_from = format_1;
              this.date_up_to = format_2;
              // console.log("groupedDates: ", groupedDates);

              // Agregar el objeto al array dataChart_labels sin modificarlo
              let dataChartEntry = {
                name: "# de pedidos",
                data: Object.values(groupedDates) // Obtener los valores del objeto groupedDates
              };
              dataChart_labels.push(dataChartEntry);

              let longestArray = 0;
              let longestArray_: any;
              dataChart_labels.forEach(element => {
                if (element.data.length > longestArray) {
                  longestArray = element.data.length;
                  longestArray_ = element;
                }
              });
              // console.log("longestArray_: ", longestArray_);

              dataChart_labels.forEach(element1 => {
                longestArray_.data.forEach(element3 => {
                  if (element1.name !== longestArray_.name) {
                    let prueba = element1.data.find(item => item.x === element3.x);
                    // console.log("prueba: ", prueba);
                    if (!prueba) {
                      element1.data.push({ x: element3.x, y: 0, count: 0 });
                    }
                  }
                });
                element1.data = element1.data.sort(function (a, b) {
                  var keyA = new Date(a.x), keyB = new Date(b.x);
                  if (keyA > keyB) return 1;
                  if (keyA < keyB) return -1;
                  return 0;
                });
              });
              // console.log("dataChart_labels: ", dataChart_labels);
              this.count_orders_date = countOrders;
              this.count_orders_complete_date = Number(this.ToFixed(countOrdersSales));
              this.refresh = true;

              this.showChart(this.obj, dataChart_labels);
            }
          });

        } else {
          let longestArray = 0;
          let longestArray_: any;
          dataChart_labels.forEach(element => {
            if (element.data.length > longestArray) {
              longestArray = element.data.length;
              longestArray_ = element
            }
          });
          // console.log("longestArray_: ", longestArray_);

          dataChart_labels.forEach(element1 => {
            longestArray_.data.forEach(element3 => {
              if (element1.name !== longestArray_.name) {
                let prueba = element1.data.find(item => item.x === element3.x);
                // console.log("prueba: ", prueba);
                if (!prueba) {
                  element1.data.push({ x: element3.x, y: 0, count: 0 });
                }
              }
            });
            element1.data = element1.data.sort(function (a, b) {
              var keyA = new Date(a.x), keyB = new Date(b.x);
              if (keyA > keyB) return 1;
              if (keyA < keyB) return -1;
              return 0;
            });
          });
          // console.log("dataChart_labels~: ", dataChart_labels);
          this.count_orders_date = countOrders;
          this.count_orders_complete_date = Number(this.ToFixed(countOrdersSales));
          this.refresh = true;

          this.showChart(this.obj, dataChart_labels);
        }
      }, 300);
    });
  }

  /**
  * Revenue chart options
  */
  async getRevenueChartOptions(obj: any) {

    //  console.log("commerces: ",response)
    // console.log("activeCommerce: ",activeCommerce)
    const today = this.calendar.getToday();
    let num_days;
    let limit_day;
    let indexDate1;
    let indexDate2;

    num_days = this.calendar.getPrev(today, 'm', 1);
    limit_day = this.calendar.getToday();
    indexDate1 = new Date(num_days.year, num_days.month - 1, num_days.day, 0, 0);
    indexDate2 = new Date(limit_day.year, limit_day.month - 1, limit_day.day, 23, 59);
    // console.log("indexDate1", indexDate1);
    // console.log("indexDate2", indexDate2);
    await this.firestore.getOne('users', this.user.uid).subscribe(async res0 => {
      // if (!res0.is_enabled) { this.router.navigate(['/verification']) }
      await this.firestore.getWhere4('orders', 'manager_uid', res0.uid, 'orders_status_uid', 'Entregada', indexDate1, indexDate2).subscribe((res2) => {
        let dataChart_labels: any = [];
        let countOrders = 0;
        let countOrdersSales = 0;
        this.refresh = false;
        this.show_message_no_data = false;
        this.count_orders_date = 0;
        this.count_orders_complete_date = 0;
        let dataChart = [];
        dataChart.length = 0;

        setTimeout(() => {
          if (res2.length !== 0) {
            // console.log("uid_manager", uid_manager, " res2", res2);
            let groupedDates = {};
            countOrders += res2.length;

            res2.forEach((orders_, index) => {
              let initTime = new Date(Date.UTC(70, 0, 1, 0, 0, 0, 0));
              initTime.setSeconds(orders_['created_at']['seconds']);
              countOrdersSales += orders_.total;

              let format = initTime.toDateString().split(" ");
              let format_;

              format_ = format[1] + " " + format[2] + " " + format[3];

              if (!groupedDates[format_]) {
                groupedDates[format_] = { x: format_, y: 0, count: 0, z: '' };
              }

              groupedDates[format_].y += 1;
              groupedDates[format_].count += Number(orders_.total);
              groupedDates[format_].z = "$ " + (groupedDates[format_].count).toFixed(2);
              // groupedDates[format_].z = "$ " + (groupedDates[format_].count).toFixed(2);

              if (index === (res2.length - 1)) {
                let day_format: any = new Date(indexDate1.getFullYear(), (indexDate1.getMonth()), indexDate1.getDate(), 23, 59, 59);
                let hour_format_1: any = new Date(indexDate2.getFullYear(), (indexDate2.getMonth()), indexDate2.getDate(), 0, 0, 0);
                let options;

                options = { year: 'numeric', month: 'short', day: 'numeric' };

                let format_1 = day_format.toLocaleDateString('es-ES', options);
                let format_2 = hour_format_1.toLocaleDateString('es-ES', options);
                this.date_from = format_1;
                this.date_up_to = format_2;
                // console.log("groupedDates: ", groupedDates);

                // Agregar el objeto al array dataChart_labels sin modificarlo
                let dataChartEntry = {
                  name: "# de pedidos",
                  data: Object.values(groupedDates) // Obtener los valores del objeto groupedDates
                };
                dataChart_labels.push(dataChartEntry);

                let longestArray = 0;
                let longestArray_: any;
                dataChart_labels.forEach(element => {
                  if (element.data.length > longestArray) {
                    longestArray = element.data.length;
                    longestArray_ = element;
                  }
                });
                // console.log("longestArray_: ", longestArray_);

                dataChart_labels.forEach(element1 => {
                  longestArray_.data.forEach(element3 => {
                    if (element1.name !== longestArray_.name) {
                      let prueba = element1.data.find(item => item.x === element3.x);
                      // console.log("prueba: ", prueba);
                      if (!prueba) {
                        element1.data.push({ x: element3.x, y: 0, count: 0 });
                      }
                    }
                  });
                  element1.data = element1.data.sort(function (a, b) {
                    var keyA = new Date(a.x), keyB = new Date(b.x);
                    if (keyA > keyB) return 1;
                    if (keyA < keyB) return -1;
                    return 0;
                  });
                });
                // console.log("dataChart_labels: ", dataChart_labels);
                this.count_orders_date = countOrders;
                this.count_orders_complete_date = Number(this.ToFixed(countOrdersSales));
                this.refresh = true;

                this.showChart(this.obj, dataChart_labels);
              }
            });

          } else {
            let longestArray = 0;
            let longestArray_: any;
            dataChart_labels.forEach(element => {
              if (element.data.length > longestArray) {
                longestArray = element.data.length;
                longestArray_ = element
              }
            });
            // console.log("longestArray_: ", longestArray_);

            dataChart_labels.forEach(element1 => {
              longestArray_.data.forEach(element3 => {
                if (element1.name !== longestArray_.name) {
                  let prueba = element1.data.find(item => item.x === element3.x);
                  // console.log("prueba: ", prueba);
                  if (!prueba) {
                    element1.data.push({ x: element3.x, y: 0, count: 0 });
                  }
                }
              });
              element1.data = element1.data.sort(function (a, b) {
                var keyA = new Date(a.x), keyB = new Date(b.x);
                if (keyA > keyB) return 1;
                if (keyA < keyB) return -1;
                return 0;
              });
            });
            // console.log("dataChart_labels~: ", dataChart_labels);
            this.count_orders_date = countOrders;
            this.count_orders_complete_date = Number(this.ToFixed(countOrdersSales));
            this.refresh = true;

            this.showChart(this.obj, dataChart_labels);
          }
        }, 300);
      });
    });
  }

  showChart(obj, dataChart_labels) {
    this.refresh = true;
    // console.log("dataChart_labels: ", dataChart_labels)
    // console.log("orders: ",this.orders)
    if (dataChart_labels.length === 0) {
      this.show_message_no_data = true;
    } else {
      this.show_message_no_data = false;
      this.revenueChartOptions = {
        series: dataChart_labels,
        chart: {
          type: "line",
          height: '400',
          parentHeightOffset: 20,
          toolbar: {
            tools: {
              download: true,
              selection: false,
              zoom: false,
              zoomin: true,
              zoomout: true,
              pan: false,
            }
          }
        },
        colors: [obj.primary, obj.secondary, obj.success, obj.info, obj.warning, obj.danger, obj.dark, obj.blue, obj.lime, obj.purple, obj.wine, obj.brown],
        grid: {
          padding: {
            bottom: -4,
          },
          borderColor: obj.gridBorder,
          xaxis: {
            lines: {
              show: true
            }
          }
        },
        xaxis: {
          type: 'datetime',
          lines: {
            show: true
          },
          axisBorder: {
            color: obj.gridBorder,
          },
          axisTicks: {
            color: obj.gridBorder,
          },
          crosshairs: {
            stroke: {
              color: obj.secondary,
            },
          },
        },
        yaxis: {
          title: {
            text: 'Cantidad de pedidos',
            style: {
              size: 9,
              color: obj.muted
            }
          },
          tickAmount: 4,
          tooltip: {
            enabled: true
          },
          crosshairs: {
            stroke: {
              color: obj.secondary,
            },
          },
          labels: {
            offsetX: 0,
          },
        },
        markers: {
          size: 0,
        },
        stroke: {
          width: 2,
          curve: "smooth",
        },
      }
    }
  }

  Alerts(header, message) {
    Swal.close();
    Swal.fire(
      header,
      message,
      'success'
    )
  }
}
