import { Injectable } from '@angular/core';
import { CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Router } from '@angular/router';
import { AuthService } from '../../service/auth/auth.service';
import { FirestoreService } from '../../service/firestore/firestore.service';
import { Observable, Subscription } from 'rxjs';

@Injectable()
export class AuthGuard implements CanActivate {
  
  constructor(
    private router: Router,
    private auth: AuthService,
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot):
    Observable<boolean> | Promise<boolean> | boolean {
    // if (localStorage.getItem('isLoggedin')) {
    //   // logged in so return true
    //   return true;
    // }

    // // not logged in so redirect to login page with the return url
    // this.router.navigate(['/auth/login'], { queryParams: { returnUrl: state.url } });
    // return false;

    return new Promise(resolve => {
      this.auth.getUser2().then(async userLocal => {
        console.log('auhtGuard() userLocal: ', userLocal);
        if (!!userLocal) {
          resolve(true);
        } else {
          this.router.navigate(['/auth/login'], { queryParams: { returnUrl: state.url } });
          resolve(false);
        }
      }, (e) => { 
        console.log(e); 
        this.router.navigate(['/auth/login'], { queryParams: { returnUrl: state.url } }); 
        resolve(false); 
      });
    });
  }
}