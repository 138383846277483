<div class="page-main-header" [class.open]="open">
    <div class="main-header-right row">
        <div class="main-header-left d-lg-none col">
            <div class="logo-wrapper">
                <!-- <a [routerLink]="'/dashboard/default'"><img class="blur-up lazyloaded"
                        src="assets/images/dashboard/multikart-logo.png" alt=""></a> -->
                <a [routerLink]="'/dashboard/default'" class="nobleui-logo" *ngIf="user?.is_enabled === true">
                    YELOU<span><strong>.APP</strong></span>
                </a>
                <div class="nobleui-logo" *ngIf="user.is_enabled !== true">
                    YELOU<span><strong>.APP</strong></span>
                </div>
            </div>
        </div>
        <div class="mobile-sidebar col">
            <div class="media-body switch-sm">
                <label class="switch">
                    <a>
                        <app-feather-icons id="sidebar-toggle" [icon]="'align-left'" (click)="collapseSidebar()">
                        </app-feather-icons>
                    </a>
                </label>
            </div>
        </div>
        <div class="nav-right col">
            <ul class="nav-menus" [ngClass]="{'open' : openNav}"
                style="display: flex; justify-content: flex-end; padding-right: 10px;">
                <!-- <li>
                    <form class="form-inline search-form">
                        <div class="form-group">
                            <input class="form-control-plaintext" [class.open]="isOpenMobile" type="search"
                                placeholder="Search..">
                            <span class="d-sm-none mobile-search">
                                <app-feather-icons [icon]="'search'" (click)="isOpenMobile=!isOpenMobile">
                                </app-feather-icons>
                            </span>
                        </div>
                    </form>
                </li> -->
                <li style="width: 100%;" *ngIf="isYellowGoAffiliate === true && cash_flow_statement">
                    <a href="javascript:void(0)" class="text-dark">
                        <span [routerLink]="['/new-order']" routerLinkActive="router-link-active"
                            class='badge bg-primary' style="font-size: medium;"> Nueva Orden</span>
                    </a>
                </li>
                <li>
                    <a href="javascript:void(0)" class="text-dark" toggleFullscreen>
                        <app-feather-icons [icon]="'maximize-2'"></app-feather-icons>
                    </a>
                </li>
                <!-- <li class="onhover-dropdown"><a class="txt-dark" href="javascript:void(0)">
                        <h6>EN</h6>
                    </a>
                    <ul class="language-dropdown onhover-show-div p-20">
                        <li><a href="javascript:void(0)" data-lng="en"><i class="flag-icon flag-icon-is"></i>
                                English</a></li>
                        <li><a href="javascript:void(0)" data-lng="es"><i class="flag-icon flag-icon-um"></i>
                                Spanish</a></li>
                        <li><a href="javascript:void(0)" data-lng="pt"><i class="flag-icon flag-icon-uy"></i>
                                Portuguese</a></li>
                        <li><a href="javascript:void(0)" data-lng="fr"><i class="flag-icon flag-icon-nz"></i> French</a>
                        </li>
                    </ul>
                </li> -->
                <li class="onhover-dropdown" (click)="alert()" *ngIf="user.is_enabled === true">
                    <span class="badge badge-pill badge-primary pull-right notification-badge"
                        *ngIf="countAlert !== 0 || cashWithdrawalWallet.length !== 0 || tableDataProducts.length !== 0">
                        {{countAlert + cashWithdrawalWallet.length + tableDataProducts.length}}</span>
                    <app-feather-icons [icon]="'bell'"></app-feather-icons><span class="dot"
                        *ngIf="countAlert !== 0 || cashWithdrawalWallet.length !== 0 || tableDataProducts.length !== 0"></span>
                    <ul class="notification-dropdown onhover-show-div" style="max-height: 500px; overflow-y: auto;">
                        <li *ngIf="cashWithdrawalWallet.length !== 0" style="background: #fffcc3;">Peticiones de
                            Efectivo<span
                                class="badge badge-pill badge-primary pull-right">{{cashWithdrawalWallet.length}}</span>
                        </li>
                        <ng-container *ngFor="let cashWallet of cashWithdrawalWallet; let i = index">
                            <li *ngIf="cashWithdrawalWallet.length !== 0">
                                <a href="javascript:void(0)"
                                    [routerLink]="['/commerces-control', cashWallet.commerce_uid]"
                                    fragment="section-ngbNav" routerLinkActive="router-link-active"
                                    style="width: 250px;">
                                    <div class="media">
                                        <div class="media-body">
                                            <h6 class="mt-0 txt-success"><span>
                                                    <app-feather-icons class="download-color font-success"
                                                        [icon]="'info'">
                                                    </app-feather-icons>
                                                </span>Código: {{cashWallet.uid}} <p class="time">
                                                    {{formatDate(cashWallet.create_date)}}</p>
                                            </h6>
                                            <p class="mb-0" style="text-transform: uppercase;">{{cashWallet.user_name}}
                                                -
                                                Retiro: $ {{ToFixed(cashWallet.amount)}} </p>
                                        </div>
                                    </div>
                                </a>
                            </li>
                        </ng-container>
                        <li *ngIf="releasedOrders.length !== 0" style="background: #fdd7ab;"># de ordenes liberadas <span
                                class="badge badge-pill badge-primary pull-right">{{countAlert}}</span></li>
                        <li *ngFor="let item of releasedOrders; let i = index">
                            <a href="javascript:void(0)" [routerLink]="['order-show/', item.uid]"
                                routerLinkActive="router-link-active" style="width: 250px;">
                                <div class="media">
                                    <div class="media-body">
                                        <h6 class="mt-0"><span>
                                                <app-feather-icons class="shopping-color" [icon]="'package'">
                                                </app-feather-icons>
                                            </span>{{item.uid}}
                                        </h6>
                                        <p class="mb-0">cliente: {{item.who_receives}}</p>
                                    </div>
                                </div>
                            </a>
                        </li>
                        <li style="background: #fffcc3;"># de ordenes creadas <span
                                class="badge badge-pill badge-primary pull-right">{{countAlert}}</span></li>
                        <li *ngFor="let item of order; let i = index">
                            <a href="javascript:void(0)" [routerLink]="['order-show/', item.uid]"
                                routerLinkActive="router-link-active" style="width: 250px;">
                                <div class="media">
                                    <div class="media-body">
                                        <h6 class="mt-0"><span>
                                                <app-feather-icons class="shopping-color" [icon]="'package'">
                                                </app-feather-icons>
                                            </span>{{item.uid}}
                                        </h6>
                                        <p class="mb-0">cliente: {{item.who_receives}}</p>
                                    </div>
                                </div>
                            </a>
                        </li>
                        <li *ngIf="order.length === 0">
                            <a href="javascript:void(0)" style="width: 250px;">
                                <div class="media">
                                    <h6 class="mt-0" style="color: black;">
                                        Sin Ordenes...
                                    </h6>
                                </div>
                            </a>
                        </li>
                        <li *ngIf="tableDataProducts.length !== 0" style="background: #fffcc3;">Sugerecia de solicitud
                            de compra<span
                                class="badge badge-pill badge-primary pull-right">{{tableDataProducts.length}}</span>
                        </li>
                        <ng-container *ngFor="let purchaseRequest of tableDataProducts; let i = index">
                            <li *ngIf="tableDataProducts.length !== 0">
                                <a href="javascript:void(0)" [routerLink]="['/solicitud-de-compra']"
                                    routerLinkActive="router-link-active" style="width: 250px;">
                                    <div class="media">
                                        <div class="media-body">
                                            <h6 class="mt-0 txt-success"><span>
                                                    <app-feather-icons class="download-color font-success"
                                                        [icon]="'info'">
                                                    </app-feather-icons>
                                                </span>
                                                Solicitud de compra - producto:
                                            </h6>
                                            <p class="mb-0" style="text-transform: uppercase;">
                                                {{purchaseRequest.name}}
                                            </p>
                                            <p class="mb-0">
                                                <!-- Inventario: {{ (purchaseRequest.inventory / purchaseRequest.unit_by_order) || 0 }} -->
                                                <!-- <br> -->
                                                Inventario: {{ purchaseRequest.stock }}
                                            </p>
                                        </div>
                                    </div>
                                </a>
                            </li>
                        </ng-container>
                        <!-- <li>
                            <div class="media">
                                <div class="media-body">
                                    <h6 class="mt-0 txt-success"><span>
                                            <app-feather-icons class="download-color font-success" [icon]="'download'">
                                            </app-feather-icons>
                                        </span>Download Complete</h6>
                                    <p class="mb-0">Lorem ipsum dolor sit amet, consectetuer.</p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="media">
                                <div class="media-body">
                                    <h6 class="mt-0 txt-danger"><span>
                                            <app-feather-icons class="alert-color font-danger" [icon]="'alert-circle'">
                                            </app-feather-icons>
                                        </span>250 MB trash files</h6>
                                    <p class="mb-0">Lorem ipsum dolor sit amet, consectetuer.</p>
                                </div>
                            </div>
                        </li> -->
                        <li class="txt-dark"><a [routerLink]="'orders'" routerLinkActive="router-link-active">Ver
                                todas</a> las ordenes</li>
                    </ul>
                </li>
                <!-- <li><a>
                        <app-feather-icons class="right_side_toggle" [icon]="'message-square'"
                            (click)="right_side_bar()">
                        </app-feather-icons><span class="dot"></span>
                    </a></li> -->
                <li class="onhover-dropdown">
                    <div class="media align-items-center"><img
                            class="align-self-center pull-right img-50 rounded-circle" [src]="photoURL"
                            style="object-fit: cover; max-width: 55px; max-height: 55px; min-width: 55px; min-height: 55px;"
                            alt="header-user">

                        <!-- <div class="dotted-animation"><span class="animate-circle"></span><span
                                class="main-circle"></span></div> -->
                    </div>
                    <ul class="profile-dropdown onhover-show-div p-20 profile-dropdown-hover" style="width: 250px;">
                        <li>
                            <a [routerLink]="'/settings/profile'">
                                <app-feather-icons [icon]="'user'"></app-feather-icons>Perfil
                            </a>
                        </li>
                        <li>
                            <a [routerLink]="'/verification'">
                                <app-feather-icons [icon]="'edit'"></app-feather-icons>Formulario de verificación
                            </a>
                        </li>
                        <li *ngIf="section_purchase_request && isYellowGoAffiliate">
                            <a [routerLink]="'/solicitud-de-compra'">
                                <app-feather-icons [icon]="'truck'"></app-feather-icons>Solicitud de compra
                            </a>
                        </li>
                        <li>
                            <a href="javascript:void(0)" (click)="onLogout($event)">
                                <app-feather-icons [icon]="'log-out'"></app-feather-icons>Cerrar Sesión
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>
            <div class="d-lg-none mobile-toggle pull-right" (click)="openMobileNav()">
                <app-feather-icons [icon]="'more-horizontal'"></app-feather-icons>
            </div>
        </div>
    </div>
</div>

<!-- Modal -->
<ng-template #lgModal let-modal>
    <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">{{view}}</h5>
        <button type="button" class="btn-close" (click)="modal.close('by: close icon')" aria-label="Close"></button>
    </div>
    <div class="modal-body" id="modal_body">
        <div class="row" *ngIf="view != 'CHAT YET'">
            <div class="col-12 col-md-12 col-xl-12">
                <h6 class="card-title">Puedes utilizar el chat para comunicar y confirmar el cambio de un producto
                    con el cliente en dado caso no tengas existencias, recuerda que el total no se modificara</h6>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-12 col-xl-12">
                <div class="row"
                    style="height: 100%; border: 1px solid black;  border-radius: 25px; margin: 10px; padding-top: 10px; padding-bottom: 20px;">
                    <div class="col-12 col-md-12 col-xl-12" *ngFor="let item of chat" style="font-size: 0.7em;">
                        <div style="display: flex; align-items: center; ">
                            <img style="height: 50px;" src="assets/img/{{item.imgChat}}">
                            <h6 class="card-title" style="font-size: 1.4em;color: black;">{{item.message}}</h6>
                        </div>
                        <br />
                        {{item.timeAgo}}
                    </div>
                    <div class="col-12 col-md-12 col-xl-12" style="margin-top: 10px;">
                        <div id="endChat"></div>
                    </div>
                    <div class="col-10 col-md-10 col-xl-10" style="background-color: white;">
                        <textarea [(ngModel)]="message" rows="1" placeholder="mensaje..." class="form-control"
                            id="exampleFormControlTextarea1" (keyup.enter)="sendMessage()"></textarea>
                    </div>
                    <div class="col-2 col-md-2 col-xl-2"
                        style="background-color: white; display: flex; align-items: center; justify-content: center;">
                        <button class="input-group-text btn btn-primary" type="button" style="border-radius: 50px;"
                            (click)="sendMessage()">
                            <app-feather-icons icon="send"></app-feather-icons>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>